import React from 'react'
import greenSVG from '../../assets/green.svg'
import pinkSVG from '../../assets/pink.svg'
import redSVG from '../../assets/red.svg'
import brownSVG from '../../assets/brown.svg'
import refreshSVG from '../../assets/refresh.svg'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const Icon = (props) => {
  return (
    <>
      {props.type === 'green' && (
        <motion.img
          transition={{ type: 'spring', stiffness: 200 }}
          whileHover={{ scale: 1.5 }}
          src={greenSVG}
          alt="green"
        />
      )}
      {props.type === 'red' && (
        <motion.img
          transition={{ type: 'spring', stiffness: 200 }}
          whileHover={{ scale: 1.5 }}
          src={redSVG}
          alt="red"
        />
      )}
      {props.type === 'pink' && (
        <motion.img
          transition={{ type: 'spring', stiffness: 200 }}
          whileHover={{ scale: 1.5 }}
          src={pinkSVG}
          alt="red"
        />
      )}
      {props.type === 'brown' && (
        <motion.img
          transition={{ type: 'spring', stiffness: 200 }}
          whileHover={{ scale: 1.5 }}
          src={brownSVG}
          alt="red"
        />
      )}
      {props.type === 'refresh' && (
        <motion.img
          transition={{ type: 'spring', stiffness: 200 }}
          whileHover={{ scale: 1.5 }}
          src={refreshSVG}
          style={props.style}
          alt="red"
        />
      )}
    </>
  )
}

Icon.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object
}

export default Icon
