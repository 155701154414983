import React, { useState } from 'react'
import classes from './style.module.css'
import PropTypes from 'prop-types'
import Tooltip from './Tooltip'
import { motion } from 'framer-motion'
import { SpinningLoader } from '@weave/design-system'

/**
 * QuickLinks to product one pager, support docs, support desk
 * @param {*} props
 * @returns
 */
const QuickLink = (props) => {
  const bgColor = props.type === 'fill' ? '#146EF5' : '#FFFFFF'
  const color = props.type === 'fill' ? '#FFFFFF' : '#146EF5'
  const [loading, setLoading] = useState(false)

  const onClick = (e) => {
    e.preventDefault()
    if (props.onClick) {
      setLoading(true)
      props.onClick(() => {
        setLoading(false)
      })
    } else if (props.url) window.open(props.url, '_blank')
  }

  const button = (
    <motion.button
      transition={{ type: 'spring', duration: 0.1, stiffness: 300 }}
      whileHover={{ scale: 1.1 }}
      onClick={onClick}
      style={{ backgroundColor: bgColor, color }}
      className={classes.QuickLink}
      name={props.name}
      disabled={loading}
    >
      {props.icon && props.iconLeft && (
        <img style={{ paddingRight: '5px' }} src={props.icon} alt="download" />
      )}
      {loading ? <SpinningLoader size='xs' /> : props.name }
      {props.icon && props.iconRight && (
        <img style={{ paddingLeft: '5px' }} src={props.icon} alt="download" />
      )}
    </motion.button>
  )

  if (!props.url && !props.onClick) {
    return <Tooltip content="Not available">{button}</Tooltip>
  } else {
    return button
  }
}

QuickLink.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconRight: PropTypes.bool,
  url: PropTypes.string,
  onClick: PropTypes.func
}

export default QuickLink
