import React from 'react'

/**
 * Fallback Screen for mobile devices
 * @returns Fallback
 */
const Fallback = () => {
  return (
    <div className="fallback">
      <h4>Oops!</h4>
      <br />
      <h6>Not available for mobile screens!</h6>
      <p>Please try on your laptop.</p>
    </div>
  )
}

export default Fallback
