import React, { useState } from 'react'
import classes from './style.module.css'
import { useSelector } from 'react-redux'

const compareSemanticVersions = (key) => (a, b) => {
  // 1. Split the strings into their parts.
  let a1
  let b1

  if (key) {
    a1 = a[key].split('.')
    b1 = b[key].split('.')
  } else {
    a1 = a.split('.')
    b1 = b.split('.')
  }
  // 2. Contingency in case there's a 4th or 5th version
  const len = Math.min(a1.length, b1.length)
  // 3. Look through each version number and compare.
  for (let i = 0; i < len; i++) {
    const a2 = +a1[i] || 0
    const b2 = +b1[i] || 0

    if (a2 !== b2) {
      return a2 > b2 ? 1 : -1
    }
  }

  // 4. We hit this if the all checked versions so far are equal
  return b1.length - a1.length
}

/**
 * PMS Version distribution for each integration
 * @returns SupportedVersions
 */
const SupportedVersions = () => {
  const currIntegration = useSelector((state) => state.biz.currIntegration)
  const versions = useSelector(
    (state) => state.biz.analytics[currIntegration]?.versions
  )
  const [searchStr, setSearchStr] = useState('')

  const sortedVersions = versions
    ? versions
      .slice()
      .sort(compareSemanticVersions('version'))
      .filter(({ version }) => (version ?? '').includes(searchStr))
    : []

  const setSearch = (e) => {
    setSearchStr(e.target.value)
  }

  return (
    <div className={classes.Container}>
      <p className={classes.Heading}>PMS Versions ({sortedVersions.length})</p>
      {sortedVersions.length > 0 && (
        <div style={{ paddingRight: '1rem', paddingBottom: '1rem' }}>
          <input
            placeholder="Search version"
            className={classes.SearchInput}
            onChange={setSearch}
            value={searchStr}
            type="text"
            name="search"
          />
        </div>
      )}
      <ul className={classes.VersionsList}>
        {sortedVersions.length > 0
          ? (
              sortedVersions.map(({ version, count }) => (
              <li key={version} className={classes.VersionRow}>
                <p>{version || 'NA'}</p>
                <div className={classes.Count}>{count}</div>
              </li>
              ))
            )
          : (
            <div className={classes.EmptyListContainer}>
              No version information available.
            </div>
            )}
      </ul>
    </div>
  )
}

export default SupportedVersions
