import React, { useState } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

const Tooltip = (props) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.children}
      {showTooltip && (
        <motion.div
          className="tooltip"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          {props.content}
        </motion.div>
      )}
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.string
}

export default Tooltip
