/**
 * Make API request using native JS Fetch API
 * @param {string} baseUrl
 * @param {string} endpoint
 * @param {string} method
 * @param {object} body
 * @returns {object} jsonResponse
 */
export const makeGETAPIRequest = async (baseUrl, endpoint, token) => {
  let apiResponse
  try {
    apiResponse = await fetch(`${baseUrl}${endpoint}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  } catch (err) {
    const errMessage = `Api Error. Message: ${err.message}`
    console.error(errMessage)
    throw new Error(errMessage)
  }

  if (!apiResponse.ok) {
    const errMessage = `Api Error. Code: ${apiResponse.status}, Message: ${apiResponse.statusText}`
    console.error(errMessage)
    throw new Error(errMessage)
  }

  const jsonResponse = await apiResponse.json()

  if (jsonResponse.error) {
    const errMessage = `Api Error. Message: ${jsonResponse.error}`
    console.error(errMessage)
    throw new Error(errMessage)
  }

  return jsonResponse
}
