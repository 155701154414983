import { jwtDecode } from 'jwt-decode'

export const TOKEN_KEY = 'token'
export const LAST_UPDATE_TIME_KEY = 'lastUpdateTime'

/**
 * Check if token is expired
 * @param {string} decodedJwt
 * @returns boolean
 */
const validDecodedJwt = (decodedJwt) => {
  if (!decodedJwt) return false
  const timeNow = new Date().getTime() / 1000
  return timeNow <= (decodedJwt.exp ?? 0)
}

/**
 * Check if token is valid
 * @param {string} decodedJwt
 * @returns boolean
 */
export const isValidJwt = (jwt) => {
  if (!jwt) return false

  try {
    const weaveJwt = jwtDecode(jwt)
    return validDecodedJwt(weaveJwt)
  } catch (error) {
    console.error('error decoding token', error)
    return false
  }
}

/**
 * Get data stored in browser
 * @param {string} key
 * @returns {string} value
 */
export const getLocalStorageItem = (key) => {
  return localStorage.getItem(key)
}

/**
 * Remove data from browser storage
 * @param {string} key
 */
export const removeLocalStorageItem = (key) => {
  return localStorage.removeItem(key)
}

/**
 * Store data in browser
 * @param {string} key
 * @returns {string} value
 */
export const setLocalStorageItem = (key, value) => {
  return localStorage.setItem(key, value)
}

export const getNowTime = () => {
  const now = new Date()

  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true // Use 12-hour time format with AM/PM
  }).format(now)

  return formattedTime
}

export const cleanDate = (date) => {
  return (new Date(date)).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
}
