import React from 'react'
import classes from './style.module.css'
import redSVG from '../../assets/red.svg'
import PropTypes from 'prop-types'

/**
 * Error Banner if there is some problem in showing the entities, writebacks
 * @param {object} props
 * @returns Banner
 */
const Banner = (props) => {
  return (
    <div className={classes.Banner}>
      <div className="intz-flex intz-justify-start intz-align-start">
        <img src={redSVG} alt="exclaim" />
      </div>
      <div className="intz-flex intz-flex-column intz-justify-start">
        <span
          className="intz-margin-left-s intz-font-bold"
          style={{ fontSize: '1rem' }}
        >
          {props.heading}
        </span>
        <span
          className="intz-margin-left-s intz-margin-top-xs"
          style={{ fontSize: '0.8rem' }}
        >
          {props.description}
        </span>
      </div>
    </div>
  )
}

Banner.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string
}

export default Banner
