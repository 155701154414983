import React, { useMemo, useState } from 'react'
import classes from './style.module.css'
import { motion } from 'framer-motion'
import {
  Table, ExportIcon, SpinningLoader
} from '@weave/design-system'
import { bizActions } from '../../store/api'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../Supportability/Icon'
import { ALL_VERTICALS, VERTICALS, VERTICAL_LOGO, Verticals } from '../../helpers/utils'
import { cleanDate } from '../../helpers/index'

const getType = (type) => {
  switch (type) {
    case 'Yes':
      return 'green'
    case 'No':
      return 'red'
  }
}

const getTypeColor = (type) => {
  switch (type) {
    case 'Cloud':
      return 'primary'
    case 'On Prem':
      return 'warn'
  }
}

function isStringInStringArray (str, arr) {
  if (!str || !arr) {
    return true
  }

  const lowerCaseSubstring = str.toLowerCase()
  return arr.map(i => i.toLowerCase()).includes(lowerCaseSubstring)
}

const Welcome = () => {
  const summary = useSelector((state) => state.biz.summary) ?? []
  const dispatch = useDispatch()
  const [vertical, setVertical] = useState('Dental')

  const setCurrIntegration = (name) => {
    dispatch(bizActions.setCurrentIntegration(name))
  }

  const filteredData = useMemo(() => {
    if (vertical === ALL_VERTICALS) {
      return summary
    } else {
      return summary
        ?.filter(intz => isStringInStringArray(vertical, VERTICALS[intz.integration]))
    }
  })

  const getVerticalStyle = (currType) => {
    if (vertical === currType) {
      return {
        backgroundColor: '#146EF5',
        color: '#FFFFFF',
        fontWeight: 'bold'
      }
    } else {
      return {
        backgroundColor: 'white',
        border: '1px solid #6F7780'
      }
    }
  }

  const getLight = (v) => {
    const S = VERTICAL_LOGO[v].light
    return <S />
  }

  const getDark = (v) => {
    const S = VERTICAL_LOGO[v].dark
    return <S />
  }

  return (
    <div style={{ padding: '2rem', flex: '1', height: '100%', overflow: 'hidden', position: 'relative' }}>
      <h2>All Partner Integrations</h2>
      <p style={{ marginTop: '6px', color: 'rgba(0,0,0,0.6)' }}>Select a vertical and click on an integration to see more information.</p>
      {summary.length > 0
        ? <>
          <div className={classes.TypeButtons}>

            {Verticals.map((v, i) => (
              <motion.button
                key={i}
                transition={{ type: 'spring', stiffness: 300 }}
                whileHover={{ scale: 1.1 }}
                style={getVerticalStyle(v)}
                onClick={() => setVertical(v)}
                className={classes.TypeButton}
                name={v}
              >
                {vertical === v ? getLight(v) : getDark(v)}&nbsp;&nbsp;{v}
              </motion.button>
            ))}
          </div>

          <Table
            rowActions={{ onRowClick: e => setCurrIntegration(e.integration) }}
            data={filteredData}
            tableInstanceId='test'
            hasFilterColumns={true}
            hasResizeColumns={true}
            hasGlobalSearch={true}
            hasResponsiveColWidths={true}
            fullHeight={true}
            tableActions={[
              {
                Icon: ExportIcon,
                label: 'Export CSV',
                onClick: (table) => table.exportData('csv', true)
              }
            ]}
            exportConfig={{
              exportFileName: () => `weave_partner_integrations_${new Date().toLocaleDateString()}`
            }}
            colConfig={[{
              id: 'Integration',
              Header: 'Integration',
              accessor: 'integration',
              sortType: 'string',
              filterConfig: {
                type: 'text'
              }
            },
            {
              id: 'Tier',
              Header: 'Tier',
              accessor: 'tier',
              sortType: 'string',
              filterConfig: {
                type: 'radio'
              },
              width: 100
            },
            {
              id: 'Type',
              Header: 'Type',
              accessor: 'type',
              sortType: 'string',
              filterConfig: {
                type: 'radio'
              },
              cellConfig: {
                element: 'Chip',
                variant: (type) => getTypeColor(type)
              },
              width: 150
            },
            {
              id: 'GA Date',
              Header: 'GA Date',
              accessor: ({ gaDate }) => cleanDate(gaDate),
              sortType: 'date',
              width: 150
            },
            {
              id: 'Last Verified',
              Header: 'Last Verified',
              accessor: ({ lastVerified }) => cleanDate(lastVerified),
              sortType: 'date',
              width: 150
            },
            {
              id: 'Patient',
              Header: 'Patient Sync',
              accessor: 'patient',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Appointment',
              Header: 'Appointment Sync',
              accessor: 'appointment',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Photo',
              Header: 'Photo Sync',
              accessor: 'photo',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Insurance',
              Header: 'Insurance Sync',
              accessor: 'insurance',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Provider',
              Header: 'Provider Sync',
              accessor: 'provider',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Aging',
              Header: 'Aging Sync',
              accessor: 'aging',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'ContactInfo',
              Header: 'ContactInfo Sync',
              accessor: 'contactInfo',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Appointment Provider',
              Header: 'Appointment Provider Sync',
              accessor: 'appointmentProvider',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Text Note',
              Header: 'Text Note Sync',
              accessor: 'textNote',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Appointment Status',
              Header: 'Appointment Status Sync',
              accessor: 'appointmentStatus',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Appointment Type',
              Header: 'Appointment Type Sync',
              accessor: 'appointmentType',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Site',
              Header: 'Site Sync',
              accessor: 'site',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            }, {
              id: 'Recall',
              Header: 'Recall Sync',
              accessor: 'recall',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Recall Type',
              Header: 'Recall Type Sync',
              accessor: 'recallType',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Payment',
              Header: 'Payment Sync',
              accessor: 'payment',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Frames Order',
              Header: 'Frames Order Sync',
              accessor: 'framesOrder',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Contacts Order',
              Header: 'Contacts Order Sync',
              accessor: 'contactsOrder',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Medical History',
              Header: 'Medical History Sync',
              accessor: 'medicalHistory',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Operatory',
              Header: 'Operatory Sync',
              accessor: 'operatory',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Document',
              Header: 'Document Sync',
              accessor: 'document',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer:
                (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Medical Conditions',
              Header: 'Medical Conditions Sync',
              accessor: 'medicalConditions',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Procedure Log',
              Header: 'Procedure Log Sync',
              accessor: 'procedureLog',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Fee Schedule',
              Header: 'Fee Schedule Sync',
              accessor: 'feeSchedule',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Fee Schedule Fee',
              Header: 'Fee Schedule Fee Sync',
              accessor: 'feeScheduleFee',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Procedure Code',
              Header: 'Procedure Code Sync',
              accessor: 'procedureCode',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Appointment Procedure',
              Header: 'Appointment Procedure Sync',
              accessor: 'appointmentProcedure',
              filterConfig: {
                type: 'radio'
              },
              width: 250,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Account',
              Header: 'Account Sync',
              accessor: 'account',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Pet',
              Header: 'Pet Sync',
              accessor: 'pet',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Save the Date',
              Header: 'Save the Date Sync',
              accessor: 'saveTheDate',
              filterConfig: {
                type: 'radio'
              },
              width: 150,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Insurance Payer Details',
              Header: 'Insurance Payer Details Sync',
              accessor: 'insurancePayerDetails',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Insurance Plan',
              Header: 'Insurance Plan Sync',
              accessor: 'insurancePlan',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Insurance Details',
              Header: 'Insurance Details Sync',
              accessor: 'insuranceDetails',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Appointment Writeback',
              Header: 'Appointment Writeback',
              accessor: 'appointmentWriteback',
              filterConfig: {
                type: 'radio'
              },
              width: 250,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Appointment Confirmation Writeback',
              Header: 'Appointment Confirmation Writeback',
              accessor: 'appointmentConfirmationWriteback',
              filterConfig: {
                type: 'radio'
              },
              width: 300,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Payment Writeback',
              Header: 'Payment Writeback',
              accessor: 'paymentWriteback',
              filterConfig: {
                type: 'radio'
              },
              width: 250,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Medical Condition Writeback',
              Header: 'Medical Condition Writeback',
              accessor: 'medicalConditionWriteback',
              filterConfig: {
                type: 'radio'
              },
              width: 300,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Medical History Writeback',
              Header: 'Medical History Writeback',
              accessor: 'medicalHistoryWriteback',
              filterConfig: {
                type: 'radio'
              },
              width: 300,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Text Writeback',
              Header: 'Text Writeback',
              accessor: 'textWriteback',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Patient Writeback',
              Header: 'Patient Writeback',
              accessor: 'patientWriteback',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            },
            {
              id: 'Document Writeback',
              Header: 'Document Writeback',
              accessor: 'documentWriteback',
              filterConfig: {
                type: 'radio'
              },
              width: 200,
              cellRenderer: (val) => <Icon type={getType(val)} />
            }
            ]}
            isPaginated={true}
          /></>
        : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <SpinningLoader size='large' />
          </div>}
    </div>
  )
}

export default Welcome
