import { authnClient, isWeaveTokenActive as isWeaveTknActive, isWeaveTokenBufferActive as isWeaveTokenBuffActive } from '@weave/auth'

const fakeLoadTime = 4000

/**
 * Stubbed weave auth package
 */
const stubbedAuthClient = {
  changeAuthMethod: () => {
    console.log('Called Stub changeAuthMethod')
  },
  signIn: async () => {
    console.log('Called Stub signIn')
    return new Promise(() => {
      setTimeout(() => {
        window.location.href = 'http://localhost:3000/sign-in/callback'
      }, fakeLoadTime)
    })
  },
  handleCallback: async () => {
    console.log('Called Stub handleCallback')
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          weaveToken: process.env.REACT_APP_TOKEN
        })
      }, fakeLoadTime)
    })
  },
  isUserAuthenticated: () => true
}

const authClient = window.location.href.includes('localhost')
  ? stubbedAuthClient
  : authnClient

export const isWeaveTokenActive = window.location.href.includes('localhost')
  ? () => true
  : isWeaveTknActive

export const isWeaveTokenBufferActive = window.location.href.includes('localhost')
  ? () => true
  : isWeaveTokenBuffActive

export default authClient
