import React from 'react'
import classes from './style.module.css'
import redSVG from '../../assets/red.svg'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { bizActions } from '../../store/api'
import { useDispatch } from 'react-redux'

/**
 * Error Banner
 * @returns ErrorBanner
 */
const ErrorBanner = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <div className={classes.ErrorContainer}>
      <div className={classes.Error}>
        <img width={'100'} src={redSVG} alt="error-banner-jumbotron" />
      </div>
      <div className={classes.Container}>
        <p className={classes.Heading}>Something is Wrong!</p>
        <p className={classes.Description}>
          {props.error}. Please try again later or contact Integrations team.
          <button
            className={classes.StartOverButton}
            type="button"
            name="Start Over"
            value="Start Over"
            onClick={() => { dispatch(bizActions.setApiError(null)); navigate('/sign-in') }}
          >
            Start Over
          </button>
        </p>
      </div>
    </div>
  )
}

ErrorBanner.propTypes = {
  error: PropTypes.string
}

export default ErrorBanner
