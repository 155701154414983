import React from 'react'
import classes from './style.module.css'
import { ENTITIES } from '../../helpers/utils'
import Banner from './Banner'
import PropTypes from 'prop-types'
import Icon from './Icon'
import { AnimatePresence, motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import Tip from './Tip'

/**
 * List of entities in Weave
 * @param {object} props
 * @returns EntityList
 */
const EntityList = (props) => {
  const currIntegration = useSelector((state) => state.biz.currIntegration)
  const capabilities = props.capabilities ?? []

  const entities = {}
  capabilities
    .filter((capability) => capability.operation === 'LIST')
    .forEach((capability) => {
      entities[capability.dataType] = {
        Read: true,
        SyncFrequency: capability.syncTiming?.syncFrequencyInMinutes
      }
    })

  const entitiesArr = Object.keys(ENTITIES)
    .map((entity) => ({
      Entity: ENTITIES[entity],
      Read: !!entities[entity]?.Read,
      SyncFrequency: getTimeUnits(entities[entity]?.SyncFrequency)
    }))
    .filter((entity) => applyTypeFilter(props.type, entity))

  return props.capabilities ?? []
    ? (
      <AnimatePresence>
        <div className={classes.List}>
          <div style={{ fontWeight: 'bold' }} className={classes.ListHeading}>
            <div style={{ width: '12dvw', wordWrap: 'break-word' }}>Entity</div>
            <div
              style={{
                width: '7dvw',
                textAlign: 'center',
                wordWrap: 'break-word'
              }}
            >
              Read
            </div>
            <div
              style={{
                width: '7dvw',
                textAlign: 'center',
                wordWrap: 'break-word'
              }}
            >
              Frequency
            </div>
          </div>
        </div>
        <motion.ul
          layout
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={{
            visible: { transition: { staggerChildren: 0.01 } }
          }}
          className={classes.List}
        >
          {entitiesArr.length > 0
            ? (
                entitiesArr.map((entity, index) => (
                <motion.li
                  layout
                  variants={{
                    hidden: { opacity: 0, scale: 0.8 },
                    visible: { opacity: 1, scale: 1 }
                  }}
                  exit={{ opacity: 1, scale: 1 }}
                  transition={{ type: 'spring' }}
                  style={{ width: '100%' }}
                  key={index}
                >
                  <div className={classes.Row}>
                    <div style={{ width: '12dvw', wordWrap: 'break-word' }}>
                      {entity.Entity} <Tip entity={entity.Entity} currIntegration={currIntegration} />
                    </div>
                    <div
                      style={{
                        width: '7dvw',
                        textAlign: 'center',
                        wordWrap: 'break-word'
                      }}
                    >
                      {entity.Read ? <Icon type="green" /> : <Icon type="red" />}
                    </div>
                    <div
                      style={{
                        width: '7dvw',
                        textAlign: 'center',
                        wordWrap: 'break-word'
                      }}
                    >
                      {entity.SyncFrequency ?? '-'}
                    </div>
                  </div>
                </motion.li>
                ))
              )
            : (
              <p>-</p>
              )}
        </motion.ul>
      </AnimatePresence>
      )
    : (
      <Banner
        heading={'Error loading entities!'}
        description={'Please contact Integrations team.'}
      />
      )
}

/**
 * Seconds to minutes or hours or days weeks
 * @param {*} secondsStr Example: 200s, 3600s, 2d, 2w
 * @returns hours or minutes or or days or weeks
 */
export const getTimeUnits = (secondsStr) => {
  if (!secondsStr) return '-'

  const seconds = parseInt(secondsStr.slice(0, -1))
  if (!seconds) return '-'

  let timeUnit = seconds / 60 // minutes
  if (timeUnit >= 60) {
    timeUnit /= 60 // hours
    if (timeUnit >= 24) {
      timeUnit /= 24 // days
      if (timeUnit >= 7) {
        timeUnit /= 7 // weeks
        return `${timeUnit}w`
      }
      return `${timeUnit}d`
    }
    return `${timeUnit}h`
  }
  return `${timeUnit}m`
}

const applyTypeFilter = (type, entity) => {
  switch (type) {
    case 'Integrated':
      return entity.Read
    case 'Not Integrated':
      return !entity.Read
    default:
      return true
  }
}

EntityList.propTypes = {
  type: PropTypes.string,
  capabilities: PropTypes.array
}

export default EntityList
