import * as Integrations from './integrations.js'

import { ReactComponent as DentalDarkSVG } from '../assets/verticals/dental-dark.svg'
import { ReactComponent as DentalLightSVG } from '../assets/verticals/dental-light.svg'
import { ReactComponent as OptoDarkSVG } from '../assets/verticals/opto-dark.svg'
import { ReactComponent as OptoLightSVG } from '../assets/verticals/opto-light.svg'
import { ReactComponent as OrthoDarkSVG } from '../assets/verticals/ortho-dark.svg'
import { ReactComponent as OrthoLightSVG } from '../assets/verticals/ortho-light.svg'
import { ReactComponent as VetDarkSVG } from '../assets/verticals/vet-dark.svg'
import { ReactComponent as VetLightSVG } from '../assets/verticals/vet-light.svg'
import { ReactComponent as HomeServicesLightSVG } from '../assets/verticals/homeservices-light.svg'
import { ReactComponent as HomeServicesDarkSVG } from '../assets/verticals/homeservices-dark.svg'
import { ReactComponent as MedicalLightSVG } from '../assets/verticals/medical-light.svg'
import { ReactComponent as MedicalDarkSVG } from '../assets/verticals/medical-dark.svg'
import { ReactComponent as MedSpaLightSVG } from '../assets/verticals/medspa-light.svg'
import { ReactComponent as MedSpaDarkSVG } from '../assets/verticals/medspa-dark.svg'
import { ReactComponent as OtherLightSVG } from '../assets/verticals/other-light.svg'
import { ReactComponent as OtherDarkSVG } from '../assets/verticals/other-dark.svg'
import { ReactComponent as eaglesoft } from '../assets/integrations/eaglesoft.svg'
import { ReactComponent as abeldent } from '../assets/integrations/abeldent.svg'
import { ReactComponent as booker } from '../assets/integrations/booker.svg'
import { ReactComponent as covetrus } from '../assets/integrations/covetrus.svg'
import { ReactComponent as infiniteVT } from '../assets/integrations/infinitevt.svg'
import { ReactComponent as dentimax } from '../assets/integrations/dentimax.svg'
import { ReactComponent as dentrix } from '../assets/integrations/dentrix.svg'
import { ReactComponent as dolphin } from '../assets/integrations/dolphin.svg'
import { ReactComponent as dsn } from '../assets/integrations/dsn.svg'
import { ReactComponent as clientrax } from '../assets/integrations/clientrax.svg'
import { ReactComponent as ecw } from '../assets/integrations/ecw.svg'
import { ReactComponent as dvmanager } from '../assets/integrations/dvmanager.svg'
import { ReactComponent as dvmax } from '../assets/integrations/dvmax.svg'
import { ReactComponent as quickbooks } from '../assets/integrations/quickbooks.svg'
import { ReactComponent as hvms } from '../assets/integrations/hvms.svg'
import { ReactComponent as traknet } from '../assets/integrations/traknet.svg'
import { ReactComponent as eddson } from '../assets/integrations/eddson.svg'
import { ReactComponent as curve } from '../assets/integrations/curve.svg'
import { ReactComponent as shepherd } from '../assets/integrations/shepherd.svg'
import { ReactComponent as ezyvet } from '../assets/integrations/ezyvet.svg'
import { ReactComponent as veradigm } from '../assets/integrations/veradigm.svg'
import { ReactComponent as intravet } from '../assets/integrations/intravet.svg'
import { ReactComponent as practicedirector } from '../assets/integrations/practicedirector.svg'
import { ReactComponent as ortho2 } from '../assets/integrations/ortho2.svg'
import { ReactComponent as eyefinity } from '../assets/integrations/eyefinity.svg'
import { ReactComponent as ascend } from '../assets/integrations/ascend.svg'
import { ReactComponent as revolution } from '../assets/integrations/revolution.svg'
import { ReactComponent as sycle } from '../assets/integrations/sycle.svg'
import { ReactComponent as nextgen } from '../assets/integrations/nextgen.svg'
import { ReactComponent as compulink } from '../assets/integrations/compulink.svg'
import { ReactComponent as denticon } from '../assets/integrations/denticon.svg'
import { ReactComponent as totalmd } from '../assets/integrations/totalmd.svg'
import { ReactComponent as daysmartvet } from '../assets/integrations/daysmartvet.svg'
import { ReactComponent as impromed } from '../assets/integrations/impromed.svg'
import { ReactComponent as via } from '../assets/integrations/via.svg'
import { ReactComponent as avimark } from '../assets/integrations/avimark.svg'
import { ReactComponent as uprise } from '../assets/integrations/uprise.svg'
import { ReactComponent as cloud9 } from '../assets/integrations/cloud9.svg'
import { ReactComponent as emr4d } from '../assets/integrations/4demr.svg'
import { ReactComponent as macpractice } from '../assets/integrations/macpractice.svg'
import { ReactComponent as maximeyes } from '../assets/integrations/maximeyes.svg'
import { ReactComponent as modmed } from '../assets/integrations/modmed.svg'
import { ReactComponent as navetor } from '../assets/integrations/navetor.svg'
import { ReactComponent as oasys } from '../assets/integrations/oasys.svg'
import { ReactComponent as odlink } from '../assets/integrations/odlink.svg'
import { ReactComponent as officemate } from '../assets/integrations/officemate.svg'
import { ReactComponent as omsvision } from '../assets/integrations/omsvision.svg'
import { ReactComponent as opendental } from '../assets/integrations/opendental.svg'
import { ReactComponent as practiceworks } from '../assets/integrations/practiceworks.svg'
import { ReactComponent as provetcloud } from '../assets/integrations/provetcloud.svg'
import { ReactComponent as weave } from '../assets/integrations/weave.svg'
import { ReactComponent as completeClinic } from '../assets/integrations/completeclinic.svg'
import { ReactComponent as athena } from '../assets/integrations/athenahealth.svg'
import { ReactComponent as crystal } from '../assets/integrations/crystal.svg'
import { ReactComponent as drchrono } from '../assets/integrations/drchrono.svg'
import { ReactComponent as cornerstone } from '../assets/integrations/cornerstone.svg'
import { ReactComponent as carestream } from '../assets/integrations/orthotrac.svg'
import { ReactComponent as nextech } from '../assets/integrations/nextech.svg'
import { ReactComponent as fuse } from '../assets/integrations/fuse.svg'
import { ReactComponent as advantage } from '../assets/integrations/advantage.svg'
import { ReactComponent as advancedmd } from '../assets/integrations/advancedmd.svg'

export const DENTAL_VERTICAL = 'Dental'
export const OPTO_VERTICAL = 'Opto'
export const VET_VERTICAL = 'Vet'
export const HOME_SERVICES_VERTICAL = 'Home Services'
export const ORTHO_VERTICAL = 'Ortho'
export const MEDICAL_VERTICAL = 'Medical'
export const ALL_VERTICALS = 'All'
export const MEDSPA_VERTICAL = 'MedSpa'

export const VERTICAL_LOGO = {
  [DENTAL_VERTICAL]: { light: DentalLightSVG, dark: DentalDarkSVG },
  [ORTHO_VERTICAL]: { light: OrthoLightSVG, dark: OrthoDarkSVG },
  [VET_VERTICAL]: { light: VetLightSVG, dark: VetDarkSVG },
  [HOME_SERVICES_VERTICAL]: { light: HomeServicesLightSVG, dark: HomeServicesDarkSVG },
  [OPTO_VERTICAL]: { light: OptoLightSVG, dark: OptoDarkSVG },
  [MEDICAL_VERTICAL]: { light: MedicalLightSVG, dark: MedicalDarkSVG },
  [MEDSPA_VERTICAL]: { light: MedSpaLightSVG, dark: MedSpaDarkSVG },
  [ALL_VERTICALS]: { light: OtherLightSVG, dark: OtherDarkSVG }
}

export const ENTITIES = {
  PERSON: 'Patient',
  APPOINTMENT: 'Appointment',
  PHOTO: 'Photo',
  INSURANCE: 'Insurance',
  PROVIDER: 'Provider',
  AGING: 'Aging',
  CONTACT_INFO: 'ContactInfo',
  APPOINTMENT_PROVIDER: 'Appointment Provider',
  TEXT_NOTE: 'Text Note',
  APPOINTMENT_STATUS: 'Appointment Status',
  APPOINTMENT_TYPE: 'Appointment Type',
  CLIENT_LOCATION: 'Site',
  RECALL: 'Recall',
  RECALL_TYPE: 'Recall Type',
  PAYMENT: 'Payment',
  FRAMES_ORDER: 'Frames Order',
  CONTACTS_ORDER: 'Contacts Order',
  MEDICAL_HISTORY: 'Medical History',
  OPERATORY: 'Operatory',
  DOCUMENT: 'Document',
  MEDICAL_CONDITION: 'Medical Conditions',
  PROCEDURE_LOG: 'Procedure log',
  FEE_SCHEDULE: 'Fee Schedule',
  FEE_SCHEDULE_FEE: 'Fee Schedule Fee',
  PROCEDURE_CODE: 'Procedure Code',
  APPOINTMENT_PROCEDURE: 'Appointment Procedure',
  ACCOUNT: 'Account',
  PET: 'Pet',
  SAVE_THE_DATE: 'Save the Date',
  INSURANCE_PAYER_DETAILS: 'Insurance Payer Details',
  INSURANCE_PLAN: 'Insurance Plan',
  INSURANCE_DETAILS: 'Insurance Details'
}

export const WRITEBACKS = {
  APPOINTMENT: 'Appointment Writeback',
  APPOINTMENT_CONFIRMATION: 'Appointment Confirmation Writeback',
  PAYMENT: 'Payment Writeback',
  MEDICAL_CONDITION: 'Medical Condition Writeback',
  MEDICAL_HISTORY: 'Medical History Writeback',
  TEXT_NOTE: 'Text Writeback',
  PERSON: 'Patient Writeback',
  DOCUMENT: 'PDF Document Writeback'
}

export const NOTES = {
  [Integrations.ABELDENT]: {
    [ENTITIES.CLIENT_LOCATION]: 'Location sync is not supported for version >= 14 and version 2022'
  },
  [Integrations.DENTRIXG5]: {
    [WRITEBACKS.DOCUMENT]: 'Supported only for versions >= 17',
    [WRITEBACKS.PERSON]: 'Update Person is supported only for versions >= 16.3. Create Person is supported only for versions >= 16.3',
    [WRITEBACKS.APPOINTMENT]: 'Supported only for versions >= 23.1'
  },
  [Integrations.OFFICEMATE]: {
    [ENTITIES.AGING]: 'Not supported for versions >= 8',
    [ENTITIES.INSURANCE]: 'Not supported for versions >= 8',
    [ENTITIES.RECALL]: 'Not supported for versions >= 12.0.2'
  },
  [Integrations.EAGLESOFT16]: {
    [ENTITIES.APPOINTMENT_STATUS]: 'Not supported for versions <= 15'
  }
}

export const MULTI_LOCATION_SUPPORT = {
  [Integrations.INFINITE_VT]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.SOFTDENT_CLOUD]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.VERADIGM]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.VERADIGM_CLOUD]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.MOCKPMS]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: false,
      practitioner: false
    }
  },
  [Integrations.ORTHOTRAC_CLOUD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.CORNERSTONE]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.ADVANTAGE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.CLIENTRAX]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.COMPLETE_CLINIC]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.DENTRIXG4_RAW]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.EASY_DENTAL_RAW]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DENTRIXG5]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DSN]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.EAGLESOFT18]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.ECW]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: false,
      practitioner: false
    }
  },
  [Integrations.EDDSON]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.EYEFINITY]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.IMPROMED]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.INTRAVET]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.MACPRACTICE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.MAXIMEYES]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.MINDBODY]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: false
    }
  },
  [Integrations.MODMED]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.EMA]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.NAVETOR]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.NEXTECH]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.OASYS]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.ODLINK]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.OFFICEMATE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.OMSVISION]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.OPENDENTAL]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.OPENDENTAL_CLOUD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.ORTHOTRAC]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DOLPHIN]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DOLPHINCLOUD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DOLPHIN_BLUE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DR_CHRONO]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.EAGLESOFT16]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.ORTHOTRAC_CLOUD_V2]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.PATTERSON_FUSE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.PRACTICE_DIRECTOR]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.PRACTICE_WORKS]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.PROVET_CLOUD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.CSV]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.CUSTOM_CONTACT]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.UNKNOWN]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.ABELDENT]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.ADVANCEDMD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.ATHENAHEALTH]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.AVIMARK]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.CLOUD9]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.COMPULINK]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.DVM_MANAGER]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.DVMAX]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.HVMS]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.STRINGSOFT]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.TRAKNET_CLOUD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.TRIPLE_CROWN]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.VIA]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.CURVE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.MINDBODYV6]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.SHEPHERD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.EZYVET]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DENTRIXG4]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.EASY_DENTAL]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.EMR4D]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.EVETPRACTICE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.PRACTICEWORKS_CLOUD_V2]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.QUICKBOOKS_ONLINE]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: false,
      practitioner: false
    }
  },
  [Integrations.VTECH_PLATINUM]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.CRYSTAL_PM]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.DAYSMARTVET]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.DENTICON]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DENTIMAX]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DENTRIX_ASCEND]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.DENTRIX_ENTERPRISE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.NEXTECH_PRACTICE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.NEXTGEN]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.ORTHO2]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.REVOLUTION]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.SOFTDENT16]: {
    patient: {
      siteID: false
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.SOFTDENT_CLOUD_V2]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.SYCLE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.TOTALMD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: false,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.TRAKNET]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.UPRISE]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.WEAVEPMS]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.WINOMS]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: false,
      practitioner: true
    }
  },
  [Integrations.WINOMS_CLOUD_V2]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.BOOKER]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.PRACTICE_WORKS_CLOUD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.SENSEI]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  },
  [Integrations.WINOMS_CLOUD]: {
    patient: {
      siteID: true
    },
    appointment: {
      siteID: true,
      operatory: true,
      practitioner: true
    }
  }
}

export const Verticals = [DENTAL_VERTICAL, OPTO_VERTICAL, VET_VERTICAL, HOME_SERVICES_VERTICAL, ORTHO_VERTICAL, MEDICAL_VERTICAL, MEDSPA_VERTICAL, ALL_VERTICALS]

export const BLACKLISTED = {
  [Integrations.ABELDENT]: true,
  [Integrations.WEAVEPMS]: true,
  [Integrations.NEXTGEN]: true,
  [Integrations.ECW]: true,
  [Integrations.PRACTICE_DIRECTOR]: true,
  [Integrations.QUICKBOOKS_ONLINE]: true,
  [Integrations.UNKNOWN]: true,
  [Integrations.CSV]: true,
  [Integrations.CUSTOM_CONTACT]: true,
  [Integrations.MOCKPMS]: true
}
export const VERTICALS = {
  [Integrations.INFINITE_VT]: [DENTAL_VERTICAL],
  [Integrations.VERADIGM]: [DENTAL_VERTICAL],
  [Integrations.VERADIGM_CLOUD]: [DENTAL_VERTICAL],
  [Integrations.SOFTDENT_CLOUD]: [DENTAL_VERTICAL],
  [Integrations.MOCKPMS]: [DENTAL_VERTICAL],
  [Integrations.ORTHOTRAC_CLOUD]: [DENTAL_VERTICAL],
  [Integrations.CORNERSTONE]: [VET_VERTICAL],
  [Integrations.ADVANTAGE]: [VET_VERTICAL],
  [Integrations.CLIENTRAX]: [VET_VERTICAL],
  [Integrations.COMPLETE_CLINIC]: [VET_VERTICAL],
  [Integrations.DENTRIXG4_RAW]: [DENTAL_VERTICAL],
  [Integrations.EASY_DENTAL_RAW]: [DENTAL_VERTICAL],
  [Integrations.DENTRIXG5]: [DENTAL_VERTICAL],
  [Integrations.DSN]: [DENTAL_VERTICAL],
  [Integrations.EAGLESOFT18]: [DENTAL_VERTICAL],
  [Integrations.ECW]: [MEDICAL_VERTICAL],
  [Integrations.EDDSON]: [DENTAL_VERTICAL],
  [Integrations.EYEFINITY]: [OPTO_VERTICAL],
  [Integrations.IMPROMED]: [VET_VERTICAL],
  [Integrations.INTRAVET]: [VET_VERTICAL],
  [Integrations.MACPRACTICE]: [DENTAL_VERTICAL, OPTO_VERTICAL, MEDICAL_VERTICAL],
  [Integrations.MAXIMEYES]: [OPTO_VERTICAL],
  [Integrations.MINDBODY]: [MEDSPA_VERTICAL],
  [Integrations.MODMED]: [MEDICAL_VERTICAL],
  [Integrations.EMA]: [MEDICAL_VERTICAL],
  [Integrations.NAVETOR]: [VET_VERTICAL],
  [Integrations.NEXTECH]: [MEDICAL_VERTICAL],
  [Integrations.OASYS]: [ORTHO_VERTICAL],
  [Integrations.ODLINK]: [OPTO_VERTICAL],
  [Integrations.OFFICEMATE]: [OPTO_VERTICAL],
  [Integrations.OMSVISION]: [DENTAL_VERTICAL],
  [Integrations.OPENDENTAL]: [DENTAL_VERTICAL],
  [Integrations.OPENDENTAL_CLOUD]: [DENTAL_VERTICAL],
  [Integrations.ORTHOTRAC]: [ORTHO_VERTICAL],
  [Integrations.DOLPHIN]: [ORTHO_VERTICAL],
  [Integrations.DOLPHINCLOUD]: [ORTHO_VERTICAL],
  [Integrations.DOLPHIN_BLUE]: [ORTHO_VERTICAL],
  [Integrations.DR_CHRONO]: [MEDICAL_VERTICAL],
  [Integrations.EAGLESOFT16]: [DENTAL_VERTICAL],
  [Integrations.ORTHOTRAC_CLOUD_V2]: [DENTAL_VERTICAL],
  [Integrations.PATTERSON_FUSE]: [DENTAL_VERTICAL],
  [Integrations.PRACTICE_DIRECTOR]: [DENTAL_VERTICAL],
  [Integrations.PRACTICE_WORKS]: [DENTAL_VERTICAL],
  [Integrations.PROVET_CLOUD]: [VET_VERTICAL],
  [Integrations.ABELDENT]: [DENTAL_VERTICAL],
  [Integrations.ADVANCEDMD]: [MEDICAL_VERTICAL],
  [Integrations.ATHENAHEALTH]: [MEDICAL_VERTICAL],
  [Integrations.AVIMARK]: [VET_VERTICAL],
  [Integrations.CLOUD9]: [ORTHO_VERTICAL],
  [Integrations.COMPULINK]: [OPTO_VERTICAL],
  [Integrations.DVM_MANAGER]: [VET_VERTICAL],
  [Integrations.DVMAX]: [VET_VERTICAL],
  [Integrations.HVMS]: [VET_VERTICAL],
  [Integrations.STRINGSOFT]: [VET_VERTICAL],
  [Integrations.TRAKNET_CLOUD]: [MEDICAL_VERTICAL],
  [Integrations.TRIPLE_CROWN]: [VET_VERTICAL],
  [Integrations.VIA]: [VET_VERTICAL],
  [Integrations.CURVE]: [DENTAL_VERTICAL],
  [Integrations.MINDBODYV6]: [MEDSPA_VERTICAL],
  [Integrations.SHEPHERD]: [VET_VERTICAL],
  [Integrations.EZYVET]: [VET_VERTICAL],
  [Integrations.DENTRIXG4]: [DENTAL_VERTICAL],
  [Integrations.EASY_DENTAL]: [DENTAL_VERTICAL],
  [Integrations.EMR4D]: [MEDSPA_VERTICAL, MEDICAL_VERTICAL],
  [Integrations.EVETPRACTICE]: [VET_VERTICAL],
  [Integrations.PRACTICEWORKS_CLOUD_V2]: [DENTAL_VERTICAL],
  [Integrations.QUICKBOOKS_ONLINE]: [MEDICAL_VERTICAL],
  [Integrations.VTECH_PLATINUM]: [VET_VERTICAL],
  [Integrations.CRYSTAL_PM]: [OPTO_VERTICAL],
  [Integrations.DAYSMARTVET]: [VET_VERTICAL],
  [Integrations.DENTICON]: [DENTAL_VERTICAL],
  [Integrations.DENTIMAX]: [DENTAL_VERTICAL],
  [Integrations.DENTRIX_ASCEND]: [DENTAL_VERTICAL],
  [Integrations.DENTRIX_ENTERPRISE]: [DENTAL_VERTICAL],
  [Integrations.NEXTECH_PRACTICE]: [MEDICAL_VERTICAL],
  [Integrations.NEXTGEN]: [DENTAL_VERTICAL],
  [Integrations.ORTHO2]: [ORTHO_VERTICAL],
  [Integrations.REVOLUTION]: [OPTO_VERTICAL],
  [Integrations.SOFTDENT16]: [DENTAL_VERTICAL],
  [Integrations.SOFTDENT_CLOUD_V2]: [DENTAL_VERTICAL],
  [Integrations.SYCLE]: [MEDICAL_VERTICAL],
  [Integrations.TOTALMD]: [MEDICAL_VERTICAL],
  [Integrations.TRAKNET]: [MEDICAL_VERTICAL],
  [Integrations.UPRISE]: [OPTO_VERTICAL],
  [Integrations.WINOMS]: [DENTAL_VERTICAL],
  [Integrations.WINOMS_CLOUD_V2]: [DENTAL_VERTICAL],
  [Integrations.BOOKER]: [MEDSPA_VERTICAL],
  [Integrations.PRACTICE_WORKS_CLOUD]: [DENTAL_VERTICAL],
  [Integrations.SENSEI]: [DENTAL_VERTICAL],
  [Integrations.WINOMS_CLOUD]: [DENTAL_VERTICAL]
}

export const LOGO = {
  [Integrations.INFINITE_VT]: infiniteVT,
  [Integrations.SOFTDENT_CLOUD]: carestream,
  [Integrations.MOCKPMS]: weave,
  [Integrations.ORTHOTRAC_CLOUD]: carestream,
  [Integrations.CORNERSTONE]: cornerstone,
  [Integrations.ADVANTAGE]: advantage,
  [Integrations.CLIENTRAX]: clientrax,
  [Integrations.VERADIGM]: veradigm,
  [Integrations.VERADIGM_CLOUD]: veradigm,
  [Integrations.COMPLETE_CLINIC]: completeClinic,
  [Integrations.DENTRIXG4_RAW]: dentrix,
  [Integrations.EASY_DENTAL_RAW]: dentrix,
  [Integrations.DENTRIXG5]: dentrix,
  [Integrations.DSN]: dsn,
  [Integrations.EAGLESOFT18]: eaglesoft,
  [Integrations.ECW]: ecw,
  [Integrations.EDDSON]: eddson,
  [Integrations.EYEFINITY]: eyefinity,
  [Integrations.IMPROMED]: impromed,
  [Integrations.INTRAVET]: intravet,
  [Integrations.MACPRACTICE]: macpractice,
  [Integrations.MAXIMEYES]: maximeyes,
  [Integrations.MINDBODY]: booker,
  [Integrations.MODMED]: modmed,
  [Integrations.EMA]: covetrus,
  [Integrations.NAVETOR]: navetor,
  [Integrations.NEXTECH]: nextech,
  [Integrations.OASYS]: oasys,
  [Integrations.ODLINK]: odlink,
  [Integrations.OFFICEMATE]: officemate,
  [Integrations.OMSVISION]: omsvision,
  [Integrations.OPENDENTAL]: opendental,
  [Integrations.OPENDENTAL_CLOUD]: opendental,
  [Integrations.ORTHOTRAC]: carestream,
  [Integrations.DOLPHIN]: dolphin,
  [Integrations.DOLPHINCLOUD]: dolphin,
  [Integrations.DOLPHIN_BLUE]: dolphin,
  [Integrations.DR_CHRONO]: drchrono,
  [Integrations.EAGLESOFT16]: eaglesoft,
  [Integrations.ORTHOTRAC_CLOUD_V2]: carestream,
  [Integrations.PATTERSON_FUSE]: fuse,
  [Integrations.PRACTICE_DIRECTOR]: practicedirector,
  [Integrations.PRACTICE_WORKS]: practiceworks,
  [Integrations.PROVET_CLOUD]: provetcloud,
  [Integrations.CSV]: weave,
  [Integrations.CUSTOM_CONTACT]: weave,
  [Integrations.UNKNOWN]: weave,
  [Integrations.ABELDENT]: abeldent,
  [Integrations.ADVANCEDMD]: advancedmd,
  [Integrations.ATHENAHEALTH]: athena,
  [Integrations.AVIMARK]: avimark,
  [Integrations.CLOUD9]: cloud9,
  [Integrations.COMPULINK]: compulink,
  [Integrations.DVM_MANAGER]: dvmanager,
  [Integrations.DVMAX]: dvmax,
  [Integrations.HVMS]: hvms,
  [Integrations.STRINGSOFT]: covetrus,
  [Integrations.TRAKNET_CLOUD]: traknet,
  [Integrations.TRIPLE_CROWN]: covetrus,
  [Integrations.VIA]: via,
  [Integrations.CURVE]: curve,
  [Integrations.MINDBODYV6]: booker,
  [Integrations.SHEPHERD]: shepherd,
  [Integrations.EZYVET]: ezyvet,
  [Integrations.DENTRIXG4]: dentrix,
  [Integrations.EASY_DENTAL]: dentrix,
  [Integrations.EMR4D]: emr4d,
  [Integrations.EVETPRACTICE]: covetrus,
  [Integrations.PRACTICEWORKS_CLOUD_V2]: practiceworks,
  [Integrations.QUICKBOOKS_ONLINE]: quickbooks,
  [Integrations.VTECH_PLATINUM]: covetrus,
  [Integrations.CRYSTAL_PM]: crystal,
  [Integrations.DAYSMARTVET]: daysmartvet,
  [Integrations.DENTICON]: denticon,
  [Integrations.DENTIMAX]: dentimax,
  [Integrations.DENTRIX_ASCEND]: ascend,
  [Integrations.DENTRIX_ENTERPRISE]: dentrix,
  [Integrations.NEXTECH_PRACTICE]: covetrus,
  [Integrations.NEXTGEN]: nextgen,
  [Integrations.ORTHO2]: ortho2,
  [Integrations.REVOLUTION]: revolution,
  [Integrations.SOFTDENT16]: carestream,
  [Integrations.SOFTDENT_CLOUD_V2]: carestream,
  [Integrations.SYCLE]: sycle,
  [Integrations.TOTALMD]: totalmd,
  [Integrations.TRAKNET]: traknet,
  [Integrations.UPRISE]: uprise,
  [Integrations.WEAVEPMS]: weave,
  [Integrations.WINOMS]: carestream,
  [Integrations.WINOMS_CLOUD_V2]: carestream,
  [Integrations.BOOKER]: booker,
  [Integrations.PRACTICE_WORKS_CLOUD]: carestream,
  [Integrations.SENSEI]: carestream,
  [Integrations.WINOMS_CLOUD]: carestream
}
export const TIERS = {
  [Integrations.SOFTDENT_CLOUD]: '5',
  [Integrations.MOCKPMS]: '3',
  [Integrations.ORTHOTRAC_CLOUD]: '3',
  [Integrations.CORNERSTONE]: '3',
  [Integrations.ADVANTAGE]: '3',
  [Integrations.CLIENTRAX]: '3',
  [Integrations.COMPLETE_CLINIC]: '3',
  [Integrations.DENTRIXG4_RAW]: '3',
  [Integrations.EASY_DENTAL_RAW]: '3',
  [Integrations.DENTRIXG5]: '4',
  [Integrations.DSN]: '3',
  [Integrations.EAGLESOFT18]: '4',
  [Integrations.INFINITE_VT]: '3',
  [Integrations.ECW]: '3',
  [Integrations.EDDSON]: '3',
  [Integrations.EYEFINITY]: '4',
  [Integrations.IMPROMED]: '3',
  [Integrations.INTRAVET]: '3',
  [Integrations.MACPRACTICE]: '4',
  [Integrations.MAXIMEYES]: '3',
  [Integrations.MINDBODY]: '3',
  [Integrations.MODMED]: '3',
  [Integrations.VERADIGM]: '3',
  [Integrations.VERADIGM_CLOUD]: '3',
  [Integrations.EMA]: '3',
  [Integrations.NAVETOR]: '3',
  [Integrations.NEXTECH]: '3',
  [Integrations.OASYS]: '1',
  [Integrations.ODLINK]: '3',
  [Integrations.OFFICEMATE]: '4',
  [Integrations.OMSVISION]: '3',
  [Integrations.OPENDENTAL]: '5',
  [Integrations.OPENDENTAL_CLOUD]: '5',
  [Integrations.ORTHOTRAC]: '4',
  [Integrations.DOLPHIN]: '4',
  [Integrations.DOLPHINCLOUD]: '3',
  [Integrations.DOLPHIN_BLUE]: '3',
  [Integrations.DR_CHRONO]: '3',
  [Integrations.EAGLESOFT16]: '4',
  [Integrations.ORTHOTRAC_CLOUD_V2]: '4',
  [Integrations.PATTERSON_FUSE]: '4',
  [Integrations.PRACTICE_DIRECTOR]: '4',
  [Integrations.PRACTICE_WORKS]: '4',
  [Integrations.PROVET_CLOUD]: '3',
  [Integrations.CSV]: '4',
  [Integrations.CUSTOM_CONTACT]: '4',
  [Integrations.UNKNOWN]: '4',
  [Integrations.ABELDENT]: '4',
  [Integrations.ADVANCEDMD]: '3',
  [Integrations.ATHENAHEALTH]: '1',
  [Integrations.AVIMARK]: '2',
  [Integrations.CLOUD9]: '3',
  [Integrations.COMPULINK]: '3',
  [Integrations.DVM_MANAGER]: '3',
  [Integrations.DVMAX]: '3',
  [Integrations.HVMS]: '3',
  [Integrations.STRINGSOFT]: '3',
  [Integrations.TRAKNET_CLOUD]: '4',
  [Integrations.TRIPLE_CROWN]: '4',
  [Integrations.VIA]: '3',
  [Integrations.CURVE]: '3',
  [Integrations.MINDBODYV6]: '3',
  [Integrations.SHEPHERD]: '3',
  [Integrations.EZYVET]: '3',
  [Integrations.DENTRIXG4]: '3',
  [Integrations.EASY_DENTAL]: '3',
  [Integrations.EMR4D]: '3',
  [Integrations.EVETPRACTICE]: '4',
  [Integrations.PRACTICEWORKS_CLOUD_V2]: '4',
  [Integrations.QUICKBOOKS_ONLINE]: '4',
  [Integrations.VTECH_PLATINUM]: '3',
  [Integrations.CRYSTAL_PM]: '4',
  [Integrations.DAYSMARTVET]: '3',
  [Integrations.DENTICON]: '3',
  [Integrations.DENTIMAX]: '3',
  [Integrations.DENTRIX_ASCEND]: '4',
  [Integrations.DENTRIX_ENTERPRISE]: '3',
  [Integrations.NEXTECH_PRACTICE]: '3',
  [Integrations.NEXTGEN]: '3',
  [Integrations.ORTHO2]: '4',
  [Integrations.REVOLUTION]: '4',
  [Integrations.SOFTDENT16]: '4',
  [Integrations.SOFTDENT_CLOUD_V2]: '4',
  [Integrations.SYCLE]: '1',
  [Integrations.TOTALMD]: '3',
  [Integrations.TRAKNET]: '1',
  [Integrations.UPRISE]: '4',
  [Integrations.WEAVEPMS]: '4',
  [Integrations.WINOMS]: '4',
  [Integrations.WINOMS_CLOUD_V2]: '3',
  [Integrations.BOOKER]: '3',
  [Integrations.PRACTICE_WORKS_CLOUD]: '3',
  [Integrations.SENSEI]: '4',
  [Integrations.WINOMS_CLOUD]: '3'
}

export const GA_DATES = {
  [Integrations.SOFTDENT_CLOUD]: new Date(2021, 8, -1, -1),
  [Integrations.MOCKPMS]: new Date(2013, 5, -1, -1),
  [Integrations.ORTHOTRAC_CLOUD]: new Date(2021, 8, -1, -1),
  [Integrations.CORNERSTONE]: new Date(2020, 3, -1, -1),
  [Integrations.ADVANTAGE]: new Date(2020, 10, -1, -1),
  [Integrations.CLIENTRAX]: new Date(2023, 1, -1, -1),
  [Integrations.COMPLETE_CLINIC]: new Date(2014, 0, -1, -1),
  [Integrations.DENTRIXG4_RAW]: new Date(2016, 1, -1, -1),
  [Integrations.EASY_DENTAL_RAW]: new Date(2016, 1, -1, -1),
  [Integrations.DENTRIXG5]: new Date(2016, 1, -1, -1),
  [Integrations.DSN]: new Date(2023, 4, -1, -1),
  [Integrations.EAGLESOFT18]: new Date(2016, 4, -1, -1),
  [Integrations.ECW]: new Date(2023, 10, -1, -1),
  [Integrations.EDDSON]: new Date(2020, 0, -1, -1),
  [Integrations.EYEFINITY]: null,
  [Integrations.IMPROMED]: null,
  [Integrations.INTRAVET]: new Date(2020, 5, -1, -1),
  [Integrations.MACPRACTICE]: null,
  [Integrations.MAXIMEYES]: null,
  [Integrations.MINDBODY]: null,
  [Integrations.MODMED]: null,
  [Integrations.EMA]: new Date(2023, 9, -1, -1),
  [Integrations.NAVETOR]: new Date(2022, 11, -1, -1),
  [Integrations.NEXTECH]: null,
  [Integrations.OASYS]: new Date(2016, 11, -1, -1),
  [Integrations.ODLINK]: null,
  [Integrations.OFFICEMATE]: null,
  [Integrations.OMSVISION]: null,
  [Integrations.OPENDENTAL]: new Date(2018, 5, -1, -1),
  [Integrations.OPENDENTAL_CLOUD]: null,
  [Integrations.ORTHOTRAC]: null,
  [Integrations.DOLPHIN]: new Date(2017, 10, -1, -1),
  [Integrations.DOLPHINCLOUD]: new Date(2023, 1, -1, -1),
  [Integrations.DOLPHIN_BLUE]: new Date(2023, 6, -1, -1),
  [Integrations.DR_CHRONO]: new Date(2020, 6, -1, -1),
  [Integrations.EAGLESOFT16]: new Date(2016, 4, -1, -1),
  [Integrations.ORTHOTRAC_CLOUD_V2]: null,
  [Integrations.PATTERSON_FUSE]: new Date(2022, 5, -1, -1),
  [Integrations.PRACTICE_DIRECTOR]: null,
  [Integrations.PRACTICE_WORKS]: null,
  [Integrations.PROVET_CLOUD]: new Date(2022, 4, -1, -1),
  [Integrations.CSV]: null,
  [Integrations.CUSTOM_CONTACT]: null,
  [Integrations.UNKNOWN]: null,
  [Integrations.ABELDENT]: null,
  [Integrations.ADVANCEDMD]: new Date(2016, 6, -1, -1),
  [Integrations.ATHENAHEALTH]: null,
  [Integrations.VERADIGM]: null,
  [Integrations.INFINITE_VT]: null,
  [Integrations.VERADIGM_CLOUD]: null,
  [Integrations.AVIMARK]: new Date(2020, 10, -1, -1),
  [Integrations.CLOUD9]: new Date(2016, 4, -1, -1),
  [Integrations.COMPULINK]: null,
  [Integrations.DVM_MANAGER]: null,
  [Integrations.DVMAX]: new Date(2023, 1, -1, -1),
  [Integrations.HVMS]: new Date(2023, 3, -1, -1),
  [Integrations.STRINGSOFT]: null,
  [Integrations.TRAKNET_CLOUD]: null,
  [Integrations.TRIPLE_CROWN]: null,
  [Integrations.VIA]: new Date(2023, 3, -1, -1),
  [Integrations.CURVE]: new Date(2019, 10, -1, -1),
  [Integrations.MINDBODYV6]: null,
  [Integrations.SHEPHERD]: null,
  [Integrations.EZYVET]: new Date(2024, 5, -1, -1),
  [Integrations.DENTRIXG4]: new Date(2016, 1, -1, -1),
  [Integrations.EASY_DENTAL]: new Date(2016, 1, -1, -1),
  [Integrations.EMR4D]: new Date(2023, 11, -1, -1),
  [Integrations.EVETPRACTICE]: null,
  [Integrations.PRACTICEWORKS_CLOUD_V2]: null,
  [Integrations.QUICKBOOKS_ONLINE]: null,
  [Integrations.VTECH_PLATINUM]: new Date(2023, 1, -1, -1),
  [Integrations.CRYSTAL_PM]: null,
  [Integrations.DAYSMARTVET]: null,
  [Integrations.DENTICON]: new Date(2019, 2, -1, -1),
  [Integrations.DENTIMAX]: new Date(2020, 2, -1, -1),
  [Integrations.DENTRIX_ASCEND]: new Date(2023, 10, -1, -1),
  [Integrations.DENTRIX_ENTERPRISE]: new Date(2018, 9, -1, -1),
  [Integrations.NEXTECH_PRACTICE]: new Date(2021, 6, -1, -1),
  [Integrations.NEXTGEN]: null,
  [Integrations.ORTHO2]: new Date(2015, 10, -1, -1),
  [Integrations.REVOLUTION]: null,
  [Integrations.SOFTDENT16]: new Date(2018, 1, -1, -1),
  [Integrations.SOFTDENT_CLOUD_V2]: null,
  [Integrations.SYCLE]: new Date(2021, 9, -1, -1),
  [Integrations.TOTALMD]: null,
  [Integrations.TRAKNET]: new Date(2020, 2, -1, -1),
  [Integrations.UPRISE]: null,
  [Integrations.WEAVEPMS]: null,
  [Integrations.WINOMS]: new Date(2017, 4, -1, -1),
  [Integrations.WINOMS_CLOUD_V2]: new Date(2021, 8, -1, -1),
  [Integrations.BOOKER]: null,
  [Integrations.PRACTICE_WORKS_CLOUD]: new Date(2021, 8, -1, -1),
  [Integrations.SENSEI]: null,
  [Integrations.WINOMS_CLOUD]: new Date(2021, 8, -1, -1)
}

export const ONE_PAGERS_URLS = {
  [Integrations.DSN]:
    'https://drive.google.com/drive/folders/1dmjPhYsH_MlMMpx6wukyhXLQd5HON6j6',
  [Integrations.ECW]:
    'https://drive.google.com/drive/folders/1wHV_E5ugNwYXl5JzakUHQATJ-ZO2mdML',
  [Integrations.INTRAVET]:
    'https://drive.google.com/drive/folders/1b400jctSz9Y49TRJRIkSUiSDaQqSVwqq',
  [Integrations.EMA]:
    'https://drive.google.com/drive/folders/1M9Z5WKYRYJP0FDTlXfDdAqoMB-AqmWBU',
  [Integrations.NAVETOR]:
    'https://drive.google.com/drive/folders/1TxT-g-qhmtWbsEJHj3OlxOCmz9xuxrai',
  [Integrations.ODLINK]:
    'https://drive.google.com/drive/folders/1aexigz6s2eJ65yrg97-4-dn0dWiUQ8LP',
  [Integrations.OMSVISION]:
    'https://drive.google.com/drive/folders/1NwyMuSiSBnU7AusrtXpjfBPx2DbKHNPM',
  [Integrations.DOLPHIN]:
    'https://drive.google.com/drive/folders/1QnVM0BIDow4YIHKU9X-nARDDLVZngnnt',
  [Integrations.DR_CHRONO]:
    'https://drive.google.com/drive/folders/16JgkvpNm0wPd_iV9Xl6Ja6V0tGHDhh1P',
  [Integrations.PATTERSON_FUSE]:
    'https://drive.google.com/drive/folders/1mfYb9aQgUObNV7rwMiSbX7pvR3X5Sewh',
  [Integrations.PROVET_CLOUD]:
    'https://drive.google.com/drive/folders/1o6IeP_qq--u1_sPwywcKFmCVJelROkTD',
  [Integrations.ATHENAHEALTH]:
    'https://drive.google.com/drive/folders/1Ch8Km9LQtZg7GrUVoPac9onxHos3zEdG',
  [Integrations.EMR4D]:
    'https://drive.google.com/drive/folders/1Xj164Cu8oPwRWwNptLX8Ris7_yOv4v1B',
  [Integrations.DENTRIX_ASCEND]:
    'https://drive.google.com/drive/folders/144uiY9ncTO0LBrvsKO-iQgKfgcDjyYq-',
  [Integrations.NEXTGEN]:
    'https://drive.google.com/drive/folders/1x-1RKIb6YTvxoDxHrBp16jDtr_GRaWZ7',
  [Integrations.REVOLUTION]:
    'https://drive.google.com/drive/folders/1ZdCiZm03AWDpk8zx0lujVooCjjl6lo4j',
  [Integrations.BOOKER]:
    'https://drive.google.com/drive/folders/1hPvJ0TFzGV1KJgE3M-JoAbnV5-QKTV69'
}

export const SUPPORT_DESK_URLS = {
  [Integrations.SOFTDENT_CLOUD]: 'https://csd-partner-support.zendesk.com',
  [Integrations.MOCKPMS]: 'mailto:integrations@getweave.com',
  [Integrations.ORTHOTRAC_CLOUD]: 'https://csd-partner-support.zendesk.com',
  [Integrations.CORNERSTONE]: null,
  [Integrations.ADVANTAGE]: null,
  [Integrations.CLIENTRAX]: null,
  [Integrations.COMPLETE_CLINIC]: null,
  [Integrations.DENTRIXG4_RAW]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.EASY_DENTAL_RAW]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.DENTRIXG5]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.DSN]: null,
  [Integrations.EAGLESOFT18]: null,
  [Integrations.ECW]: 'mailto:fhirfighters@eclinicalworks.com',
  [Integrations.EDDSON]: null,
  [Integrations.EYEFINITY]: null,
  [Integrations.IMPROMED]: null,
  [Integrations.INTRAVET]: null,
  [Integrations.MACPRACTICE]: null,
  [Integrations.MAXIMEYES]: null,
  [Integrations.MINDBODY]: 'GA May 2018',
  [Integrations.MODMED]: 'GA Jan 2021',
  [Integrations.EMA]: null,
  [Integrations.NAVETOR]: 'https://gitlab.com/lisa.jones/navetor-weave',
  [Integrations.NEXTECH]: null,
  [Integrations.OASYS]: null,
  [Integrations.ODLINK]: 'mailto:craig@odlink.com',
  [Integrations.OFFICEMATE]: null,
  [Integrations.OMSVISION]: null,
  [Integrations.OPENDENTAL]: null,
  [Integrations.OPENDENTAL_CLOUD]: null,
  [Integrations.ORTHOTRAC]: null,
  [Integrations.DOLPHIN]:
    'https://www.anywheredolphin.com/secure/login.aspx?ReturnUrl=%2fsecure%2fintegrators%2fIntegrator.aspx',
  [Integrations.DOLPHINCLOUD]:
    'https://www.anywheredolphin.com/secure/login.aspx?ReturnUrl=%2fsecure%2fintegrators%2fIntegrator.aspx',
  [Integrations.DOLPHIN_BLUE]:
    'https://pattersonb2c.b2clogin.com/pattersonb2c.onmicrosoft.com/b2c_1a_prod_signinwithpwreset2m/oauth2/v2.0/authorize?client_id=675385a7-2275-4f84-9594-01b7f72b69d3&redirect_uri=https%3A%2F%2Fblue.dolphinimaging.com%2F&response_type=id_token%20token&scope=openid%20email%20offline_access%20https%3A%2F%2FPattersonB2C.onmicrosoft.com%2F1b1576d2-25fd-42fc-8395-b793b5056934%2Fpat.dolphin.api%20https%3A%2F%2FPattersonB2C.onmicrosoft.com%2F1b1576d2-25fd-42fc-8395-b793b5056934%2Fpattersonuniversalid%20https%3A%2F%2FPattersonB2C.onmicrosoft.com%2F1b1576d2-25fd-42fc-8395-b793b5056934%2Fpat.platform.api&state=OpenIdConnect.AuthenticationProperties%3DTEyXuDbMZM_Yqm4HBKgTThJ1SZfCdF4CFAO0hYBkxNqZ9m5m77rt3G7Dkqn9ovtvnLz6PNCsszSRu_I7_WFRcL-8_7ppPSoTlGh3m5cpipM4bLOIvhBRjA_ZeTtRVY8V6Tosg7saD04REl0jAxnrq1V8BlMY2mO2QUJ_pGJYHczzC3QtpcjqKHe-t_SuqEjFea-AeGlLuUvZ7gTSIP4SFffoVKsQOaEo_u_CcfQGwRr28hZYOKJ9sX4ewAdLSWaB&response_mode=form_post&nonce=638230472601471507.NWIzNmQwZDQtODcyMi00OTI1LTk2MWYtY2ZhZThkY2IxNWI4YTdiNmM5MTctYjFjZC00MDViLWIzOTEtMjQ2OTkyOTE5Mzc1&p=B2C_1A_PROD_SignInWithPwReset2m&x-client-SKU=ID_NET461&x-client-ver=5.3.0.0#!/dashboard',
  [Integrations.DR_CHRONO]: 'https://app.drchrono.com/api-docs/v4/',
  [Integrations.EAGLESOFT16]: null,
  [Integrations.ORTHOTRAC_CLOUD_V2]:
    'https://partner-api-portal-beta.csdental.com/',
  [Integrations.PATTERSON_FUSE]:
    'https://test-fuse-web.practicemgmt-test.pattersondevops.com/',
  [Integrations.PRACTICE_DIRECTOR]:
    'https://api.practicedirector.com/documentation/pma/v1/home',
  [Integrations.PRACTICE_WORKS]: null,
  [Integrations.PROVET_CLOUD]: 'https://developers.provetcloud.com/',
  [Integrations.CSV]: null,
  [Integrations.CUSTOM_CONTACT]: null,
  [Integrations.UNKNOWN]: null,
  [Integrations.ABELDENT]: null,
  [Integrations.ADVANCEDMD]: 'https://login.advancedmd.com/',
  [Integrations.ATHENAHEALTH]:
    'https://developer.api.athena.io/ams-portal/apps',
  [Integrations.AVIMARK]: 'https://vetdata.freshdesk.com/login/',
  [Integrations.CLOUD9]:
    'https://weave.cloud9ortho.com/html5/LoginAccount/Login',
  [Integrations.COMPULINK]: null,
  [Integrations.DVM_MANAGER]: 'GA Mar 2023',
  [Integrations.DVMAX]: 'GA Mar 2023',
  [Integrations.HVMS]: 'GA Mar 2023',
  [Integrations.STRINGSOFT]: 'GA Mar 2023',
  [Integrations.TRAKNET_CLOUD]: null,
  [Integrations.TRIPLE_CROWN]: 'GA Mar 2023',
  [Integrations.VIA]: 'GA Mar 2023',
  [Integrations.CURVE]: 'https://api-docs.curvehero.com/',
  [Integrations.EZYVET]: null,
  [Integrations.SHEPHERD]: null,
  [Integrations.DENTRIXG4]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.EASY_DENTAL]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.EMR4D]: 'mailto:rpollack@4d-emr.com',
  [Integrations.EVETPRACTICE]: null,
  [Integrations.PRACTICEWORKS_CLOUD_V2]:
    'https://partner-api-portal-beta.csdental.com/',
  [Integrations.QUICKBOOKS_ONLINE]:
    'https://developer.intuit.com/app/developer/qbo/docs/api',
  [Integrations.VTECH_PLATINUM]: null,
  [Integrations.CRYSTAL_PM]: null,
  [Integrations.DAYSMARTVET]: 'https://sandbox.vettersoftware.com/docs',
  [Integrations.DENTICON]: 'https://api.denticon.com/',
  [Integrations.DENTIMAX]: null,
  [Integrations.DENTRIX_ASCEND]: 'mailto:jay.wood@henryscheinone.com',
  [Integrations.DENTRIX_ENTERPRISE]: 'mailto:mike.snow@henryscheinone.com',
  [Integrations.NEXTECH_PRACTICE]:
    'https://nextechsystems.github.io/practiceplusapidocspub/#introduction',
  [Integrations.NEXTGEN]: null,
  [Integrations.ORTHO2]: null,
  [Integrations.VERADIGM]: null,
  [Integrations.INFINITE_VT]: null,
  [Integrations.VERADIGM_CLOUD]: null,
  [Integrations.REVOLUTION]:
    'https://services.revolutionehr.com/ws/resources/javadoc/index.html',
  [Integrations.SOFTDENT16]: null,
  [Integrations.SOFTDENT_CLOUD_V2]:
    'https://partner-api-portal-beta.csdental.com/',
  [Integrations.SYCLE]: null,
  [Integrations.TOTALMD]: null,
  [Integrations.TRAKNET]: null,
  [Integrations.UPRISE]: null,
  [Integrations.WEAVEPMS]: null,
  [Integrations.WINOMS]: 'https://csd-partner-support.zendesk.com',
  [Integrations.WINOMS_CLOUD_V2]:
    'https://csd-partner-access-api-prod.portal.azure-api.net/signin?ReturnUrl=%2Fdeveloper',
  [Integrations.BOOKER]: 'https://developers-staging.booker.com/signin',
  [Integrations.PRACTICE_WORKS_CLOUD]:
    'https://csd-partner-access-api-prod.portal.azure-api.net/signin?ReturnUrl=%2Fdeveloper',
  [Integrations.SENSEI]: 'https://csd-partner-support.zendesk.com',
  [Integrations.WINOMS_CLOUD]:
    'https://csd-partner-access-api-prod.portal.azure-api.net/signin?ReturnUrl=%2Fdeveloper'
}

export const PARTNER_DOCS_URLS = {
  [Integrations.SOFTDENT_CLOUD]:
    'https://csd-partner-access-api-prod.portal.azure-api.net/signin?ReturnUrl=%2Fdeveloper',
  [Integrations.MOCKPMS]: null,
  [Integrations.ORTHOTRAC_CLOUD]:
    'https://csd-partner-access-api-prod.portal.azure-api.net/signin?ReturnUrl=%2Fdeveloper',
  [Integrations.CORNERSTONE]: 'https://io.datapointapi.com/documentation',
  [Integrations.ADVANTAGE]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.CLIENTRAX]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.COMPLETE_CLINIC]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.DENTRIXG4_RAW]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.EASY_DENTAL_RAW]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.DENTRIXG5]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.DSN]: null,
  [Integrations.EAGLESOFT18]: null,
  [Integrations.ECW]: null,
  [Integrations.EDDSON]: null,
  [Integrations.EYEFINITY]: 'https://developer-sandbox.eyefinity.com/al-pe',
  [Integrations.IMPROMED]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.INTRAVET]: null,
  [Integrations.MACPRACTICE]: null,
  [Integrations.MAXIMEYES]: 'https://ioqa.maximeyes.com/maximeyes/index#!/',
  [Integrations.MINDBODY]: 'https://developers.mindbodyonline.com/Home/LogIn',
  [Integrations.MODMED]: 'https://www.modmed.com/modmed-synapsys-api/',
  [Integrations.EMA]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.NAVETOR]: 'https://dataapi.navetor.com/docs/',
  [Integrations.NEXTECH]:
    'https://nextechsystems.github.io/selectapidocspub//#introduction',
  [Integrations.OASYS]: null,
  [Integrations.ODLINK]:
    'https://docs.google.com/document/d/1ZS3H6Efo71nfkVYWLnDwoQUB_Sd9dMd86_QujPBOmeg/edit',
  [Integrations.OFFICEMATE]: null,
  [Integrations.OMSVISION]: null,
  [Integrations.OPENDENTAL]: null,
  [Integrations.OPENDENTAL_CLOUD]: null,
  [Integrations.ORTHOTRAC]: null,
  [Integrations.DOLPHIN]:
    'https://www.anywheredolphin.com/secure/login.aspx?ReturnUrl=%2fsecure%2fintegrators%2fIntegrator.aspx',
  [Integrations.DOLPHINCLOUD]:
    'https://www.anywheredolphin.com/secure/login.aspx?ReturnUrl=%2fsecure%2fintegrators%2fIntegrator.aspx',
  [Integrations.DOLPHIN_BLUE]:
    'https://pattersonb2c.b2clogin.com/pattersonb2c.onmicrosoft.com/b2c_1a_prod_signinwithpwreset2m/oauth2/v2.0/authorize?client_id=675385a7-2275-4f84-9594-01b7f72b69d3&redirect_uri=https%3A%2F%2Fblue.dolphinimaging.com%2F&response_type=id_token%20token&scope=openid%20email%20offline_access%20https%3A%2F%2FPattersonB2C.onmicrosoft.com%2F1b1576d2-25fd-42fc-8395-b793b5056934%2Fpat.dolphin.api%20https%3A%2F%2FPattersonB2C.onmicrosoft.com%2F1b1576d2-25fd-42fc-8395-b793b5056934%2Fpattersonuniversalid%20https%3A%2F%2FPattersonB2C.onmicrosoft.com%2F1b1576d2-25fd-42fc-8395-b793b5056934%2Fpat.platform.api&state=OpenIdConnect.AuthenticationProperties%3DTEyXuDbMZM_Yqm4HBKgTThJ1SZfCdF4CFAO0hYBkxNqZ9m5m77rt3G7Dkqn9ovtvnLz6PNCsszSRu_I7_WFRcL-8_7ppPSoTlGh3m5cpipM4bLOIvhBRjA_ZeTtRVY8V6Tosg7saD04REl0jAxnrq1V8BlMY2mO2QUJ_pGJYHczzC3QtpcjqKHe-t_SuqEjFea-AeGlLuUvZ7gTSIP4SFffoVKsQOaEo_u_CcfQGwRr28hZYOKJ9sX4ewAdLSWaB&response_mode=form_post&nonce=638230472601471507.NWIzNmQwZDQtODcyMi00OTI1LTk2MWYtY2ZhZThkY2IxNWI4YTdiNmM5MTctYjFjZC00MDViLWIzOTEtMjQ2OTkyOTE5Mzc1&p=B2C_1A_PROD_SignInWithPwReset2m&x-client-SKU=ID_NET461&x-client-ver=5.3.0.0#!/dashboard',
  [Integrations.DR_CHRONO]: 'https://app.drchrono.com/api-docs/v4/',
  [Integrations.EAGLESOFT16]: null,
  [Integrations.ORTHOTRAC_CLOUD_V2]:
    'https://partner-api-portal-beta.csdental.com/',
  [Integrations.PATTERSON_FUSE]:
    'https://test-fuse-web.practicemgmt-test.pattersondevops.com/',
  [Integrations.PRACTICE_DIRECTOR]:
    'https://api.practicedirector.com/documentation/pma/v1/home',
  [Integrations.PRACTICE_WORKS]: null,
  [Integrations.PROVET_CLOUD]: 'https://developers.provetcloud.com/',
  [Integrations.CSV]: null,
  [Integrations.CUSTOM_CONTACT]: null,
  [Integrations.UNKNOWN]: null,
  [Integrations.ABELDENT]: null,
  [Integrations.ADVANCEDMD]: 'https://login.advancedmd.com/',
  [Integrations.ATHENAHEALTH]:
    'https://developer.api.athena.io/ams-portal/apps',
  [Integrations.AVIMARK]: 'https://vetdata.freshdesk.com/login/',
  [Integrations.CLOUD9]:
    'https://weave.cloud9ortho.com/html5/LoginAccount/Login',
  [Integrations.COMPULINK]: null,
  [Integrations.DVM_MANAGER]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.DVMAX]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.HVMS]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.STRINGSOFT]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.TRAKNET_CLOUD]: null,
  [Integrations.TRIPLE_CROWN]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.VIA]: 'https://vetdata.freshdesk.com/support/home',
  [Integrations.CURVE]: 'https://api-docs.curvehero.com/',
  [Integrations.EZYVET]: null,
  [Integrations.SHEPHERD]: null,
  [Integrations.DENTRIXG4]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.EASY_DENTAL]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.EMR4D]: 'https://apistage4.developer.azure-api.net/',
  [Integrations.EVETPRACTICE]: null,
  [Integrations.PRACTICEWORKS_CLOUD_V2]:
    'https://partner-api-portal-beta.csdental.com/',
  [Integrations.QUICKBOOKS_ONLINE]:
    'https://developer.intuit.com/app/developer/qbo/docs/api',
  [Integrations.VTECH_PLATINUM]: null,
  [Integrations.CRYSTAL_PM]: null,
  [Integrations.DAYSMARTVET]: 'https://sandbox.vettersoftware.com/docs',
  [Integrations.DENTICON]: 'https://api.denticon.com/',
  [Integrations.DENTIMAX]: null,
  [Integrations.DENTRIX_ASCEND]: 'https://portal.hs1api.com/',
  [Integrations.DENTRIX_ENTERPRISE]: 'https://ddp.dentrix.com/auth/login',
  [Integrations.NEXTECH_PRACTICE]:
    'https://nextechsystems.github.io/practiceplusapidocspub/#introduction',
  [Integrations.NEXTGEN]: 'https://www.nextgen.com/api',
  [Integrations.ORTHO2]: null,
  [Integrations.REVOLUTION]:
    'https://services.revolutionehr.com/ws/resources/javadoc/index.html',
  [Integrations.SOFTDENT16]: null,
  [Integrations.SOFTDENT_CLOUD_V2]:
    'https://partner-api-portal-beta.csdental.com/',
  [Integrations.SYCLE]: null,
  [Integrations.TOTALMD]: null,
  [Integrations.TRAKNET]: null,
  [Integrations.UPRISE]:
    'https://www.youruprise.com/PatientOutreachWebApi/swagger/index.html',
  [Integrations.WEAVEPMS]: null,
  [Integrations.WINOMS]: null,
  [Integrations.VERADIGM]: null,
  [Integrations.INFINITE_VT]: null,
  [Integrations.VERADIGM_CLOUD]: null,
  [Integrations.WINOMS_CLOUD_V2]:
    'https://csd-partner-access-api-prod.portal.azure-api.net/signin?ReturnUrl=%2Fdeveloper',
  [Integrations.BOOKER]: 'https://developers-staging.booker.com/signin',
  [Integrations.PRACTICE_WORKS_CLOUD]:
    'https://csd-partner-access-api-prod.portal.azure-api.net/signin?ReturnUrl=%2Fdeveloper',
  [Integrations.SENSEI]:
    'https://csd-partner-access-api-prod.portal.azure-api.net/signin?ReturnUrl=%2Fdeveloper',
  [Integrations.WINOMS_CLOUD]:
    'https://csd-partner-access-api-prod.portal.azure-api.net/signin?ReturnUrl=%2Fdeveloper'
}

export const DESCRIPTIONS = {
  [Integrations.SOFTDENT_CLOUD]:
    'Softdent Cloud is one of the PMSs offered by Carestream Cloud. There is an on-prem equivalent "SoftDent" as well which is also integrated with Weave.',
  [Integrations.MOCKPMS]:
    'Mock PMS is a dummy PMSs written by Weave for Weave TAS purposes.',
  [Integrations.ORTHOTRAC_CLOUD]:
    'OrthoTrac Cloud is one of the PMS offered by Carestream Cloud. There is an on-prem equivalent "OrthoTrac" as well which is also integrated with Weave.',
  [Integrations.CORNERSTONE]:
    null,
  [Integrations.VERADIGM]: '',
  [Integrations.INFINITE_VT]: '',
  [Integrations.VERADIGM_CLOUD]: '',
  [Integrations.ADVANTAGE]:
    'Advantage is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.CLIENTRAX]:
    'ClienTrax is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.COMPLETE_CLINIC]:
    'CompleteClinic is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.DENTRIXG4_RAW]:
    'DentrixG4Raw is one of the early integrations with Weave. There are not many customers who are using this since it is very old and not even supported by the partner HenrySchein itself. We only support versions 11.*, 13.*, 14.*, 4.*, 5.* as part of DentrixG4. Greater versions are supported in our DentrixG5 integration.',
  [Integrations.EASY_DENTAL_RAW]:
    'EasyDentalRaw is one of the early integrations with Weave. There are not many customers who are using this since it is very old and not even supported by the partner HenrySchein itself.  We only support versions 11.*, 13.*, 14.*, 4.*, 5.* as part of EasyDentalRaw. Greater versions are supported in our DentrixG5 integration.',
  [Integrations.DENTRIXG5]:
    'DentrixG5 is one of the early integrations with Weave. Most of the Weave customers are using this and there is full support provided by HenrySchein for this integration. We support all Dentrix versions >= 15',
  [Integrations.DSN]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.EAGLESOFT18]:
    'Eaglesoft18 is one of the early integrations with Weave. There are a lot of customers who are using this since it is very popular in the Dental vertical',
  [Integrations.ECW]:
    'EClinicalWorks or ECW is an FHIR based integration.',
  [Integrations.EDDSON]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.EYEFINITY]:
    'Eyefinity is the only integration that uses authorization code authentication which requires the user to sign in to the PMS before coming to Weave',
  [Integrations.IMPROMED]:
    'Impromed is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.INTRAVET]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.MACPRACTICE]:
    'MacPractice PMS is only available for Mac devices.',
  [Integrations.MAXIMEYES]:
    null,
  [Integrations.MINDBODY]:
    'Mindbody is a SOAP API based integration.',
  [Integrations.MINDBODYV6]:
    'MindbodyV6 is a REST API based integration.',
  [Integrations.MODMED]:
    null,
  [Integrations.EMA]:
    'EMA is similar to ModMed integration.',
  [Integrations.NAVETOR]:
    null,
  [Integrations.NEXTECH]:
    null,
  [Integrations.OASYS]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.ODLINK]:
    null,
  [Integrations.OFFICEMATE]:
    null,
  [Integrations.OMSVISION]:
    null,
  [Integrations.OPENDENTAL]:
    'OpenDental is an onprem integration but there is an API provided by OpenDental which should be used instead of direct DB queries.',
  [Integrations.OPENDENTAL_CLOUD]: null,
  [Integrations.ORTHOTRAC]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.DOLPHIN]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner. There is a cloud version available called as DolphinCloud. It is encouraged to have office update to cloud version if possible.',
  [Integrations.DOLPHINCLOUD]:
    'DolphinCloud is a cloud version of the on-prem Dolphin integration. It is encouraged to have office update to cloud version if possible',
  [Integrations.DOLPHIN_BLUE]:
    'DolphinBlue is a cloud version of the on-prem Dolphin integration. It is encouraged to have office update to cloud version if possible',
  [Integrations.DR_CHRONO]:
    null,
  [Integrations.EAGLESOFT16]:
    'Eaglesoft16 is pretty old integration and there are not many customers who use it. If there are any technical issues, there is no support given by the partner website for Eaglesoft16',
  [Integrations.ORTHOTRAC_CLOUD_V2]:
    'OrthotracCloudV2 is the updated version of OrthoTracCloud. It is encouraged that the offices update to V2 since the support for V1 will slowly go away.',
  [Integrations.PATTERSON_FUSE]:
    'PattersonFuse is a file based integration. It is not API based or DB based. The blob files are stored in SFTP server in Weave and data is synced in batches. It is a special integration and one of a kind integration.',
  [Integrations.PRACTICE_DIRECTOR]:
    null,
  [Integrations.PRACTICE_WORKS]:
    null,
  [Integrations.PROVET_CLOUD]:
    null,
  [Integrations.CSV]:
    'Weave offering that syncs the office data to Weave using CSV uploads.',
  [Integrations.CUSTOM_CONTACT]:
    'Offices are write custom contacts in Weave using this feature.',
  [Integrations.UNKNOWN]:
    null,
  [Integrations.ABELDENT]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.ADVANCEDMD]:
    'AdvancedMD is a SOAP based integration. There is a REST API available but it is not yet integrated with Weave.',
  [Integrations.ATHENAHEALTH]:
    'Athenahealth is a FHIR based integration.',
  [Integrations.AVIMARK]:
    'Avimark is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.CLOUD9]:
    'Cloud9 is special in terms of the API window. We can only sync in night time.',
  [Integrations.COMPULINK]:
     'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.DVM_MANAGER]:
    'DVM-Manager is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.DVMAX]:
    'DV-Max is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.HVMS]:
    'HVMS is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.STRINGSOFT]:
    'StringSoft is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.TRAKNET_CLOUD]:
    null,
  [Integrations.TRIPLE_CROWN]:
    'TripleCrown is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.VIA]:
    'VIA is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.CURVE]:
    null,
  [Integrations.SHEPHERD]:
    null,
  [Integrations.EZYVET]:
    null,
  [Integrations.DENTRIXG4]:
    'DentrixG4 is one of the early integrations with Weave. There are not many customers who are using this since it is very old and not even supported by the partner HenrySchein itself.  We only support versions 11.*, 13.*, 14.*, 4.*, 5.* as part of DentrixG4. Greater versions are supported in our DentrixG5 integration.',
  [Integrations.EASY_DENTAL]:
   'EasyDentalis one of the early integrations with Weave. There are not many customers who are using this since it is very old and not even supported by the partner HenrySchein itself.  We only support versions 11.*, 13.*, 14.*, 4.*, 5.* as part of EasyDental. Greater versions are supported in our DentrixG5 integration.',
  [Integrations.EMR4D]:
    '4DEMR is Weave\'s only integration in the MedSpa space.',
  [Integrations.EVETPRACTICE]:
    'EVetPractice is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.PRACTICEWORKS_CLOUD_V2]:
    'PracticeWorksCloudV2 is the updated version of OrthoTracCloud. It is encouraged that the offices update to V2 since the support for V1 will slowly go away.',
  [Integrations.QUICKBOOKS_ONLINE]:
    'This integration is now sunset.',
  [Integrations.VTECH_PLATINUM]:
    'VTechPlatinum is one the many PMSs offered by Covetrus Marketplace.',
  [Integrations.CRYSTAL_PM]:
    'The writebacks are done using the CrystalPM utility exe provided by CrystalPM.',
  [Integrations.DAYSMARTVET]:
    null,
  [Integrations.DENTICON]:
    null,
  [Integrations.DENTIMAX]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.DENTRIX_ASCEND]:
    'DentrixAscend is cloud version of DentrixG5 which is onprem.',
  [Integrations.DENTRIX_ENTERPRISE]:
    null,
  [Integrations.NEXTECH_PRACTICE]:
    null,
  [Integrations.NEXTGEN]:
    null,
  [Integrations.ORTHO2]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.REVOLUTION]:
    null,
  [Integrations.SOFTDENT16]:
    'Softdent is a very old integration for which we do not have any documentation provided by Softdent. The only technical point of contact we have is tom.rankin@csdental.com',
  [Integrations.SOFTDENT_CLOUD_V2]:
    'SoftDentCloudV2 is the updated version of OrthoTracCloud. It is encouraged that the offices update to V2 since the support for V1 will slowly go away.',
  [Integrations.SYCLE]:
    null,
  [Integrations.TOTALMD]:
     'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.TRAKNET]:
    'It is a backdoor integration, so we do not have a partnership or support from the partner.',
  [Integrations.UPRISE]:
    null,
  [Integrations.WEAVEPMS]:
    'Only for sales demo purposes.',
  [Integrations.WINOMS]:
     'It is a backdoor integration, so we do not have a partnership or support from the partner. There is a cloud version available and offices are encouraged to use cloud version of WinOMS if they want to integrate with Weave.',
  [Integrations.WINOMS_CLOUD_V2]:
    'WinOMSCloudV2 is the updated version of OrthoTracCloud. It is encouraged that the offices update to V2 since the support for V1 will slowly go away.',
  [Integrations.BOOKER]:
    null,
  [Integrations.PRACTICE_WORKS_CLOUD]:
    'PracticeWorksCloud is one of the PMS offered by Carestream Cloud. There is an on-prem equivalent "OrthoTrac" as well which is also integrated with Weave.',
  [Integrations.SENSEI]:
    'Senseiloud is one of the PMS offered by Carestream Cloud. There is an on-prem equivalent "OrthoTrac" as well which is also integrated with Weave.',
  [Integrations.WINOMS_CLOUD]:
    'WinOMSCloud is one of the PMS offered by Carestream Cloud. There is an on-prem equivalent "OrthoTrac" as well which is also integrated with Weave.'
}

export const PMS_ADAPTERS = {
  '-advancedmd': 'AdvancedMD',
  '-advantage': 'Advantage',
  '-athenahealth': 'AthenaHealth',
  '-avimark': 'Avimark',
  '-booker': 'Booker',
  '-clientrax': 'ClienTrax',
  '-cloud9': 'Cloud 9',
  '-Compulink Opto': 'Compulink',
  '-curve': 'Curve',
  '-ezyvet': 'Ezyvet',
  '-daysmartvet': 'Day Smart Vet',
  '-Denticon': 'Denticon',
  '-Dentrix G4 Raw': 'DentrixG4Raw',
  '-dentrixAscend': 'Dentrix Ascend',
  '-shepherd': 'Shepherd',
  '-dolphinblue': 'DolphinBlue',
  '-dolphincloud': 'DolphinCloud',
  '-dvmax': 'DVMAX',
  '-dvmmanager': 'DVM Manager',
  '-Easy Dental Raw': 'Easy Dental Raw',
  '-eclinicalworks': 'EClinicalWorks',
  '-ema': 'EMA',
  '-EMR4D': 'EMR4D',
  '-evetpractice': 'EVet Practice',
  '-eyefinity': 'Eyefinity',
  '-hvms': 'HVMS',
  '-impromed': 'Impromed',
  '-maximeyes': 'Maximeyes',
  '-MINDBODY': 'Mindbody',
  '-mindbodyV6': 'MindbodyV6',
  '-modmed': 'ModMed',
  '-navetor': 'Navetor',
  '-nextech': 'Nextech',
  '-nextechpractice': 'Nextech Practice',
  '-Oasys': 'Oasys',
  '-odlink': 'ODLink',
  '-Office Mate': 'Officemate',
  '-Ortho2': 'Ortho2',
  '-orthotraccloud': 'OrthoTrac Cloud',
  '-orthotraccloudv2': 'OrthoTrac Cloud V2',
  '-pattersonfuse': 'Patterson Fuse',
  '-practiceworkscloud': 'PracticeWorks Cloud',
  '-practiceworkscloudv2': 'PRACTICEWORKS Cloud V2',
  '-provetcloud': 'ProvetCloud',
  '-quickbooksonline': 'Quickbooks Online',
  '-Revolution': 'Revolution',
  '-senseicloud': 'Sensei',
  '-softdentcloud': 'Softdent Cloud',
  '-softdentcloudv2': 'Softdent Cloud V2',
  '-sycle': 'Sycle',
  '-uprise': 'Uprise',
  '-via': 'VIA',
  '-vtechplatinum': 'vTech Platinum',
  '-weavepms': 'WeavePMS',
  '-winomscloudv2': 'WinOMS Cloud V2',
  abeldent: 'Abeldent',
  'Compulink Opto': 'Compulink',
  cornerstone: 'Cornerstone',
  'Crystal PM': 'Crystal',
  dentimax: 'Dentimax',
  dentrixenterprise: 'Dentrix Enterprise',
  dentrixg4: 'DentrixG4',
  dentrixg5: 'DentrixG5',
  dolphin: 'Dolphin',
  drchrono: 'Dr Chrono',
  'DSN Dental Exec': 'DSN Dental Exec',
  'Eaglesoft 18': 'Eaglesoft18',
  eaglesoft16: 'Eaglesoft16',
  'Easy Dental': 'Easy Dental',
  eddson: 'Eddson',
  intravet: 'Intravet',
  macpractice: 'Macpractice',
  Oasys: 'Oasys',
  'Office Mate': 'Officemate',
  OMSVision: 'OMSVision',
  opendental: 'Open Dental',
  Ortho2: 'Ortho2',
  OrthoTrac: 'OrthoTrac',
  'Practice Web': 'Practice Web',
  practicedirector: 'Practice Director',
  practiceworks: 'PracticeWorks',
  softdent: 'SoftDent16',
  traknet: 'Traknet',
  WinOMS: 'WinOMS'
}

export const getPMStoAdapterMap = () => {
  const pmsToAdapter = {}
  for (const key in PMS_ADAPTERS) {
    const value = PMS_ADAPTERS[key]
    pmsToAdapter[value] = key
  }
  console.log(pmsToAdapter)

  return pmsToAdapter
}
