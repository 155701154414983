import React from 'react'
import classes from './style.module.css'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const bgColor = {
  lavendar: '#DCEBFE',
  peach: '#C5F6DA',
  strawberry: '#FEE5FF',
  orange: '#FFEECC'
}

const textColor = {
  lavendar: '#146EF5',
  peach: '#18703E',
  strawberry: '#5E1870',
  orange: '#C16C0B'
}

/**
 * Chips like SelfInstallable, OnPrem, GADate
 * @param {*} props
 * @returns InfoChip
 */
const InfoChip = (props) => {
  const type = props.type ?? 'lavendar'

  return (
    <motion.span
      transition={{ type: 'spring', duration: 0.1, stiffness: 300 }}
      whileHover={{ cursor: 'pointer', scale: 1.1 }}
      style={{ backgroundColor: bgColor[type], color: textColor[type] }}
      className={classes.InfoChip}
    >
      <span>{props.value}</span>
    </motion.span>
  )
}

InfoChip.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string
}

export default InfoChip
