import React, { useState } from 'react'
import classes from './style.module.css'
import EntityList from './EntityList'
import WritebackList from './WritebackList'
import { useSelector } from 'react-redux'
import { ENTITIES, WRITEBACKS } from '../../helpers/utils'
import { motion } from 'framer-motion'
import MultiSupport from './MultiSupport'

/**
 * Entities, Writebacks, MultiSupport information for an integration
 * @returns Supportability
 */
const Supportability = () => {
  const [entityType, setEntityType] = useState('All')
  const [writebacksType, setWritebacksType] = useState('All')
  const currIntegration = useSelector((state) => state.biz.currIntegration)
  const supportability = useSelector(
    (state) => state.biz.integrations[currIntegration]
  )

  const getEntityTypeStyle = (currType) => {
    if (entityType === currType) {
      return {
        backgroundColor: '#146EF5',
        color: '#FFFFFF',
        fontWeight: 'bold'
      }
    } else {
      return {
        backgroundColor: 'white',
        border: '1px solid #6F7780'
      }
    }
  }

  const getWritebacksTypeStyle = (currType) => {
    if (writebacksType === currType) {
      return {
        backgroundColor: '#146EF5',
        color: '#FFFFFF',
        fontWeight: 'bold'
      }
    } else {
      return {
        backgroundColor: 'white',
        border: '1px solid #6F7780'
      }
    }
  }

  const entitiesLength = Object.keys(ENTITIES).length
  const writebacksLength = Object.keys(WRITEBACKS).length

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
      }}
    >

      <div className={classes.Container}>
        <p className={classes.Heading}>Entities ({entitiesLength})</p>
        <div className={classes.TypeButtons}>
          <motion.button
            transition={{ type: 'spring', stiffness: 300 }}
            whileHover={{ scale: 1.1 }}
            style={getEntityTypeStyle('All')}
            onClick={() => setEntityType('All')}
            className={classes.TypeButton}
            name={'All'}
          >
            All
          </motion.button>
          <motion.button
            transition={{ type: 'spring', stiffness: 300 }}
            whileHover={{ scale: 1.1 }}
            style={getEntityTypeStyle('Integrated')}
            onClick={() => setEntityType('Integrated')}
            className={classes.TypeButton}
            name={'Integrated'}
          >
            Integrated
          </motion.button>
          <motion.button
            transition={{ type: 'spring', stiffness: 300 }}
            whileHover={{ scale: 1.1 }}
            style={getEntityTypeStyle('Not Integrated')}
            onClick={() => setEntityType('Not Integrated')}
            className={classes.TypeButton}
            name={'Not Integrated'}
          >
            Not Integrated
          </motion.button>
        </div>
        <EntityList
          capabilities={supportability.capabilities}
          type={entityType}
        />
      </div>
      <div className={classes.Container}>
        <p className={classes.Heading}>Writebacks ({writebacksLength})</p>
        <div className={classes.TypeButtons}>
          <motion.button
            transition={{ type: 'spring', stiffness: 300 }}
            whileHover={{ scale: 1.1 }}
            style={getWritebacksTypeStyle('All')}
            onClick={() => setWritebacksType('All')}
            className={classes.TypeButton}
            name={'All'}
          >
            All
          </motion.button>
          <motion.button
            transition={{ type: 'spring', stiffness: 300 }}
            whileHover={{ scale: 1.1 }}
            style={getWritebacksTypeStyle('Integrated')}
            onClick={() => setWritebacksType('Integrated')}
            className={classes.TypeButton}
            name={'Integrated'}
          >
            Integrated
          </motion.button>
          <motion.button
            transition={{ type: 'spring', stiffness: 300 }}
            whileHover={{ scale: 1.1 }}
            style={getWritebacksTypeStyle('Not Integrated')}
            onClick={() => setWritebacksType('Not Integrated')}
            className={classes.TypeButton}
            name={'Not Integrated'}
          >
            Not Integrated
          </motion.button>
        </div>
        <WritebackList
          capabilities={supportability.capabilities}
          type={writebacksType}
        />
      </div>
      <div className={classes.Container}>
        <p className={classes.Heading}>Multi Support</p>
        <p style={{ marginTop: '10px' }}>Can we separate patients & appointments based on location, practitioner or operatory ?</p>
        <MultiSupport />
      </div>
    </div>
  )
}

export default Supportability
