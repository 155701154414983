import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './App.css'
import ErrorBanner from './components/Dashboard/ErrorBanner'
import Login from './components/Login/template'
import Dashboard from './components/Dashboard/template'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Loader from './components/Loader/template'
import * as Helpers from './helpers/index'
import { logout, authActions } from './store/api'
import authClient, { isWeaveTokenBufferActive, isWeaveTokenActive } from './auth/auth'
import { ThemeProvider } from '@weave/design-system'

const refreshApp = (loading, isAuthenticated, err) => {
  const otherRoutes = () => {
    if (isAuthenticated) {
      return <>
      { loading && <Route path="/sign-in/callback" element={<Loader />} /> }
      { !loading && <Route path="/sign-in/callback" element={<Dashboard />} /> }
      { !loading && <Route exact path="/sign-in" element={<Dashboard />} /> }
    </>
    } else {
      return <>
      { loading && <Route path="/sign-in/callback" element={<Loader />} /> }
      { !loading && <Route path="/sign-in/callback" element={<Dashboard />} /> }
      { !loading && <Route exact path="/sign-in" element={<Login />} /> }
    </>
    }
  }

  return (
    <ThemeProvider includeEmotionTheme>
      <Router>
        <Routes>
          {!isAuthenticated && <Route exact path="/" element={<Login />} />}
          {isAuthenticated && <Route exact path="/" element={<Dashboard />} />}

          {err && <Route path="/sign-in/callback" element={<ErrorBanner error={err} />} />}
          {err && <Route exact path="/sign-in" element={<ErrorBanner error={err} />} />}

          {otherRoutes()}
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

/**
 * Base App
 * @returns App
 */
const App = () => {
  const loading = useSelector((state) => state.biz.loading)
  const err = useSelector((state) => state.biz.error)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!authClient.isUserAuthenticated() && !isWeaveTokenBufferActive()) {
      dispatch(logout())
    } else if (!Helpers.getLocalStorageItem(Helpers.TOKEN_KEY)) {
      dispatch(logout())
    } else {
      const token = Helpers.getLocalStorageItem(Helpers.TOKEN_KEY)
      if (token && Helpers.isValidJwt(token)) {
        dispatch(authActions.authenticate(token))
      } else if (!isWeaveTokenActive() && isWeaveTokenBufferActive()) {
        try {
          authClient.refreshWeaveToken().then((newToken) => {
            Helpers.setLocalStorageItem(Helpers.TOKEN_KEY, newToken)
            dispatch(authActions.authenticate(newToken))
          })
        } catch (err) {
          dispatch(authActions.logout())
        }
      }
    }
  }, [authClient.isUserAuthenticated(), isWeaveTokenBufferActive(), isWeaveTokenActive()])

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  return refreshApp(loading, isAuthenticated, err)
}

export default App
