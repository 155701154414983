import React from 'react'
import { useSelector } from 'react-redux'
import classes from './style.module.css'
import Icon from './Icon'
import { WRITEBACKS } from '../../helpers/utils'
import Banner from './Banner'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import Tip from './Tip'

/**
 * List of writebacks in Weave
 * @param {object} props
 * @returns WritebackList
 */
const WritebackList = (props) => {
  const currIntegration = useSelector((state) => state.biz.currIntegration)
  const capabilities = props.capabilities ?? []
  const type = props.type

  const writebacks = getWritebacks(type, capabilities)

  return capabilities ?? []
    ? (
      <AnimatePresence>
        <div className={classes.List}>
          <div style={{ fontWeight: 'bold' }} className={classes.ListHeading}>
            <div style={{ width: '15dvw', wordWrap: 'break-word' }}>
              Writeback
            </div>
            <div
              style={{
                width: '7dvw',
                textAlign: 'center',
                wordWrap: 'break-word'
              }}
            >
              Integrated
            </div>
          </div>
        </div>
        <motion.ul
          layout
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={{
            visible: { transition: { staggerChildren: 0.01 } }
          }}
          style={{ height: '33rem' }}
          className={classes.List}
        >
          {writebacks.length > 0
            ? (
                writebacks.map((writeback, index) => (
                <motion.li
                  layout
                  variants={{
                    hidden: { opacity: 0, scale: 0.8 },
                    visible: { opacity: 1, scale: 1 }
                  }}
                  exit={{ opacity: 1, scale: 1 }}
                  transition={{ type: 'spring' }}
                  style={{ width: '100%' }}
                  key={index}
                >
                  <div className={classes.Row}>
                    <div style={{ width: '15dvw', wordWrap: 'break-word' }}>
                      {writeback.Writeback} <Tip entity={writeback.Writeback} currIntegration={currIntegration} />
                    </div>
                    <div
                      style={{
                        width: '7dvw',
                        textAlign: 'center',
                        wordWrap: 'break-word'
                      }}
                    >
                      {writeback.Supported
                        ? (
                          <Icon type="green" />
                          )
                        : (
                          <Icon type="red" />
                          )}
                    </div>
                  </div>
                </motion.li>
                ))
              )
            : (
              <p>-</p>
              )}
        </motion.ul>
      </AnimatePresence>
      )
    : (
      <Banner
        heading={'Error loading writebacks!'}
        description={'Please contact Integrations team.'}
      />
      )
}

const applyTypeFilter = (type, writeback) => {
  switch (type) {
    case 'Integrated':
      return writeback.Supported
    case 'Not Integrated':
      return !writeback.Supported
    default:
      return true
  }
}

const getWritebacks = (type, capabilities) => {
  const entities = {}

  capabilities
    .filter((capability) => capability.operation === 'UPDATE')
    .forEach((capability) => {
      entities[capability.dataType] = {
        Update: true
      }
    })

  capabilities
    .filter((capability) => capability.operation === 'CREATE')
    .forEach((capability) => {
      entities[capability.dataType] = {
        ...entities[capability.dataType],
        Create: true
      }
    })

  const writebacks = Object.keys(WRITEBACKS)
    .map((writeback) => ({
      Writeback: WRITEBACKS[writeback],
      Supported: !!entities[writeback]?.Create || !!entities[writeback]?.Update
    }))
    .filter((writeback) => applyTypeFilter(type, writeback))

  return writebacks
}

WritebackList.propTypes = {
  type: PropTypes.string,
  capabilities: PropTypes.array
}

export default WritebackList
