import React from 'react'
import classes from './style.module.css'
import weaveSVG from '../../assets/weave.svg'
import { bizActions, logout, refresh } from '../../store/api'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import * as Helpers from '../../helpers/index'
import Icon from '../Supportability/Icon'
import { useNavigate } from 'react-router-dom'

/**
 * Header banner consisting of Weave Logo and Logout button
 * @returns Header
 */
const Header = () => {
  const refreshing = useSelector(state => state.biz.refreshing)
  const refreshError = useSelector(state => state.biz.refreshError)
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const navigate = useNavigate()

  const handleLogout = (e) => {
    dispatch(logout())
    navigate('/sign-in')
  }

  const handleRefresh = (e) => {
    e.preventDefault()
    dispatch(refresh(token))
  }

  const getStyle = () => {
    if (refreshing) {
      return { animation: 'rotateAnimation 1s linear infinite' }
    }
    return null
  }

  const reset = (e) => {
    e.preventDefault()
    dispatch(bizActions.setCurrentIntegration(''))
  }

  return (
    <div className={classes.HeaderContainer}>
      <div onClick={(e) => reset(e)} className={classes.LogoContainer}>
        <img src={weaveSVG} alt="weave-logo" />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className={classes.RefreshContainer} style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginLeft: '0.5rem', marginRight: '1rem', fontWeight: 'bold', color: 'white' }}>
            {!refreshing && !refreshError
              ? `Last Refresh at ${Helpers.getLocalStorageItem(Helpers.LAST_UPDATE_TIME_KEY)}`
              : refreshError ? 'Unable to refresh!' : 'Refreshing...'} </p>
        </div>
        <motion.button
          transition={{ type: 'spring', duration: 0.1, stiffness: 300 }}
          whileHover={{ cursor: refreshing ? 'not-allowed' : 'pointer', scale: 1.1 }}
          onClick={handleRefresh}
          disabled={refreshing}
          style={{ justifyContent: 'center', display: 'flex', width: '6rem' }}
          className={classes.RefreshButton}>
          {refreshing ? <Icon style={getStyle()} type='refresh' /> : 'Refresh'}
        </motion.button>
        <motion.button
          transition={{ type: 'spring', duration: 0.1, stiffness: 300 }}
          whileHover={{ cursor: 'pointer', scale: 1.1 }}
          onClick={handleLogout}
          className={classes.LogoutButton}>
          Logout
        </motion.button>
      </div>
    </div>
  )
}

export default Header
