import React from 'react'
import classes from './style.module.css'

/**
 * Loader that spins when fetching data from INTZ API
 * @returns
 */
const Loader = () => {
  return (
    <div className={classes.LoaderContainer}>
      <div className={classes.WelcomeContainer}>
        <div className={classes.Loader}></div>
        <p className={classes.WelcomeHeading}>Hold on!</p>
        <p className={classes.WelcomeDescription}>
          Sip your coffee while we fetch all the latest information for you!
        </p>
      </div>
    </div>
  )
}

export default Loader
