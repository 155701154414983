import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ErrorBanner from './components/Dashboard/ErrorBanner'

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const handleError = (error, errorInfo) => {
      console.error('Error caught by error boundary:', error, errorInfo)
      setHasError(true)
      setError(error)
      // You can log the error or send it to a logging service
    }

    // Attach the error handler to the global error event
    window.addEventListener('error', handleError)

    return () => {
      // Remove the error handler when the component unmounts
      window.removeEventListener('error', handleError)
    }
  }, [])

  if (hasError) {
    // You can customize the fallback UI here
    return <ErrorBanner error={error.message} />
  }

  return <>{children}</>
}

ErrorBoundary.propTypes = {
  children: PropTypes.any
}

export default ErrorBoundary
