import React from 'react'
import classes from './style.module.css'
import Icon from './Icon'
import PropTypes from 'prop-types'
import { MULTI_LOCATION_SUPPORT } from '../../helpers/utils'
import { useSelector } from 'react-redux'

/**
 * Display if we can separate Patients and Appointments based on SiteID, Practitioner or Operatory
 * @returns
 */
const MultiSupport = () => {
  const currIntegration = useSelector((state) => state.biz.currIntegration)
  const multiSupport = MULTI_LOCATION_SUPPORT[currIntegration]

  return (<>
    <div className={classes.List}>
      <div style={{ fontWeight: 'bold' }} className={classes.ListHeading}>
        <div style={{ width: '10dvw', wordWrap: 'break-word' }}>Entity</div>
        <div
          style={{
            width: '10dvw',
            textAlign: 'center',
            wordWrap: 'break-word'
          }}
        >
          By Location
        </div>
        <div
          style={{
            width: '10dvw',
            textAlign: 'center',
            wordWrap: 'break-word'
          }}
        >
          By Practitioner
        </div>
        <div
          style={{
            width: '10dvw',
            textAlign: 'center',
            wordWrap: 'break-word'
          }}
        >
          By Operatory
        </div>
      </div>
    </div>
    <ul className={classes.List}>
      <li style={{ width: '100%' }}>
        <div className={classes.Row}>
          <div style={{ width: '10dvw', wordWrap: 'break-word' }}>
            {'Patient'}
          </div>
          <div
            style={{
              width: '10dvw',
              textAlign: 'center',
              wordWrap: 'break-word'
            }}
          >
            {multiSupport.patient.siteID
              ? (
                <Icon type="green" />
                )
              : (
                <Icon type="red" />
                )}
          </div>
          <div
            style={{
              width: '10dvw',
              textAlign: 'center',
              wordWrap: 'break-word'
            }}
          >
            {multiSupport.patient.practitioner
              ? (
                <Icon type="green" />
                )
              : (
                <Icon type="red" />
                )}
          </div>
          <div
            style={{
              width: '10dvw',
              textAlign: 'center',
              wordWrap: 'break-word'
            }}
          >
            {multiSupport.patient.operatory
              ? (
                <Icon type="green" />
                )
              : (
                <Icon type="red" />
                )}
          </div>
        </div>
      </li>
      <li style={{ width: '100%' }}>
        <div className={classes.Row}>
          <div style={{ width: '10dvw', wordWrap: 'break-word' }}>
            {'Appointment'}
          </div>
          <div
            style={{
              width: '10dvw',
              textAlign: 'center',
              wordWrap: 'break-word'
            }}
          >
            {multiSupport.appointment.siteID
              ? (
                <Icon type="green" />
                )
              : (
                <Icon type="red" />
                )}
          </div>
          <div
            style={{
              width: '10dvw',
              textAlign: 'center',
              wordWrap: 'break-word'
            }}
          >
            {multiSupport.appointment.practitioner
              ? (
                <Icon type="green" />
                )
              : (
                <Icon type="red" />
                )}
          </div>
          <div
            style={{
              width: '10dvw',
              textAlign: 'center',
              wordWrap: 'break-word'
            }}
          >
            {multiSupport.appointment.operatory
              ? (
                <Icon type="green" />
                )
              : (
                <Icon type="red" />
                )}
          </div>
        </div>
      </li>
    </ul>
  </>)
}

MultiSupport.propTypes = {
  type: PropTypes.string,
  capabilities: PropTypes.array
}

export default MultiSupport
