import React, { useState } from 'react'
import classes from './style.module.css'
import { ReactComponent as InfoSVG } from '../../assets/info.svg'
import { NOTES } from '../../helpers/utils'
import PropTypes from 'prop-types'

/**
 * InfoTip to show some nuances w.r.to an entity or writeback
 * @param {object} props
 * @returns Tip
 */
const Tip = (props) => {
  const [showTip, setShowTip] = useState(false)
  const notes = NOTES[props.currIntegration]
  if (!notes) {
    return null
  }

  const note = notes[props.entity]
  if (!note) {
    return null
  }

  const handleHover = () => {
    setShowTip(true)
  }

  const handleLeave = () => {
    setShowTip(false)
  }

  return (
    <>
      <div
        onMouseLeave={handleLeave}
        onMouseEnter={handleHover}
        style={{ paddingLeft: '7px', display: 'inline-block' }}
      >
        <InfoSVG />
      </div>
      {
        showTip &&
        <div
          className={classes.InfoNote}
        >
          {note}
        </div>
      }
    </>
  )
}

Tip.propTypes = {
  currIntegration: PropTypes.string,
  entity: PropTypes.string
}

export default Tip
