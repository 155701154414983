import React from 'react'
import classes from './style.module.css'
import { useSelector } from 'react-redux'
import { SYNC_RESTRICTION } from './utils.js'

/**
 * Time window in which sync is allowed for an integration
 * @returns SyncRestriction
 */
const SyncRestriction = () => {
  const currIntegration = useSelector((state) => state.biz.currIntegration)
  const syncRestriction = SYNC_RESTRICTION[currIntegration]

  return (
    <div className={classes.Container}>
      <p className={classes.Heading}>Sync Restriction</p>
      <p className={classes.Description}>{syncRestriction || 'NA'}</p>
    </div>
  )
}

export default SyncRestriction
