import React, { useEffect } from 'react'
import classes from './style.module.css'
import IntegrationInfo from '../IntegrationInfo/template'
import Header from '../Header/template'
import { authActions, bizActions, getData } from '../../store/api'
import { useDispatch, useSelector } from 'react-redux'
import authnClient from '../../auth/auth'
import Loader from '../Loader/template'
import * as Helpers from '../../helpers'

/**
 * Main Dashboard
 * @returns Dashboard
 */
const Dashboard = () => {
  const dispatch = useDispatch()
  const integrations = useSelector((state) => state.biz.integrations)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const token = useSelector((state) => state.auth.token)
  const error = useSelector((state) => state.biz.error)

  useEffect(() => {
    if (!isAuthenticated) {
      authnClient.changeAuthMethod('okta')
      authnClient
        .handleCallback()
        .then((token) => {
          if (!integrations && !error) {
            Helpers.setLocalStorageItem(Helpers.TOKEN_KEY, token.weaveToken)
            dispatch(authActions.authenticate(token.weaveToken))
            dispatch(getData(token.weaveToken))
          }
        })
        .catch((err) => {
          console.log(err)
          dispatch(bizActions.setApiError(err.message))
        })
    } else if (!integrations && !error) {
      const token = Helpers.getLocalStorageItem(Helpers.TOKEN_KEY)
      dispatch(authActions.authenticate(token))
      dispatch(getData(token))
    }
  }, [dispatch, getData, authActions.authenticate])

  return isAuthenticated && token
    ? (
    <div className={classes.DashboardContainer}>
      <Header />
      <div className={classes.InfoContainer}>
        <IntegrationInfo />
      </div>
    </div>
      )
    : <Loader />
}

export default Dashboard
