/* eslint-disable no-unused-vars */
import React from 'react'
import classes from './style.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { motion } from 'framer-motion'
import backSVG from '../../assets/back.svg'
import {
  GA_DATES,
  LOGO,
  ONE_PAGERS_URLS,
  DESCRIPTIONS
  , VERTICALS
} from '../../helpers/utils'
import downloadSVG from '../../assets/download.svg'
import arrowRightSVG from '../../assets/arrow-right.svg'
import Supportability from '../Supportability/template'
import SyncRestriction from '../SyncRestriction/template'
import SupportedVersions from '../SupportedVersions/template'
import Welcome from './Welcome'
import InfoChip from './InfoChip'
import QuickLink from './QuickLink'
import { bizActions } from '../../store/api'
import { cleanDate } from '../../helpers'
import { downloadCSV } from './api'
import * as Helpers from '../../helpers/index'
/**
 * Integration Information Container
 * @returns IntegrationInfo
 */
const IntegrationInfo = () => {
  const currIntegration = useSelector((state) => state.biz.currIntegration)
  const integrations = useSelector((state) => state.biz.integrations)
  const dispatch = useDispatch()

  let integrationType,
    gADate,
    Logo,
    description,
    selfInstall,
    onePagerUrl
  if (integrations) {
    const integrationInfo = integrations[currIntegration]
    if (integrationInfo) {
      integrationType = integrationInfo.type ?? ''
      selfInstall = integrationInfo.selfInstall ?? ''

      gADate = GA_DATES[currIntegration]
      Logo = LOGO[currIntegration]
      description = DESCRIPTIONS[currIntegration]

      onePagerUrl = ONE_PAGERS_URLS[currIntegration]
      // supportUrl = SUPPORT_DESK_URLS[currIntegration]
      // docsUrl = PARTNER_DOCS_URLS[currIntegration]
    }
  }

  const reset = (e) => {
    e.preventDefault()
    dispatch(bizActions.setCurrentIntegration(''))
  }

  const exportToCSV = (cleanup) => {
    alert('Download job started. It may take some time to get the list. It will be downloaded automatically once done.')
    console.log(currIntegration)
    downloadCSV(currIntegration, Helpers.getLocalStorageItem(Helpers.TOKEN_KEY))
      .then((data) => {
        if (!data || data.length === 0) {
          return
        }
        const columnNames = Object.keys(data[0])

        // Generate CSV content with column names followed by data rows
        const csvContent = columnNames.join(',') + '\n' + data.map(row => Object.values(row).join(',')).join('\n')
        const blob = new Blob([csvContent], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)

        // Create a temporary link
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `locations-${currIntegration}.csv`)

        // Programmatically trigger the download
        document.body.appendChild(link)
        link.click()

        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      })
      .catch((err) => {
        console.log(err)
        alert('Error in downloading CSV. Please logout, login and try again.')
      })
      .finally(() => {
        cleanup()
      })
  }

  const toTitleCase = (str) => {
    return str.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  return currIntegration
    ? (
      <div className={classes.IntegrationInfoContainer}>
        <div className={classes.IntegrationHeader}>
          <div className={classes.Container}>
            <motion.img
              className={classes.GoBack}
              onClick={(e) => reset(e)}
              transition={{ type: 'spring', stiffness: 200 }}
              whileHover={{ scale: 1.2, opacity: 0.8 }}
              src={backSVG}
              alt="red"
            />
            <p className={classes.IntegrationHeading}>{toTitleCase(currIntegration)}</p>
            <div className={classes.ChipsContainer}>
              {integrationType === 'CLOUD' && (
                <InfoChip value="Cloud" type="lavendar" />
              )}
              {integrationType === 'ON_PREM' && (
                <InfoChip value="OnPrem" type="orange" />
              )}
              {selfInstall === 'AVAILABLE' && (
                <InfoChip value="Self Installable" type="peach" />
              )}
              {selfInstall === 'NOT_AVAILABLE' && (
                <InfoChip value="Not Self Installable" type="peach" />
              )}
              {gADate && <InfoChip value={`GA ${cleanDate(gADate)}`} type="strawberry" />}
              {VERTICALS[currIntegration]?.map((v, i) => (<InfoChip key={i} value={v} type="strawberry" />))}
            </div>
            <p className={classes.IntegrationDescription}>{description}</p>
          </div>
          <Logo style={{ height: '10dvh', width: '20dvw', marginBottom: '1rem' }} alt="pms-svg" />
        </div>
        <div className={classes.IntegrationQuickLinks}>
          {/* <QuickLink url={docsUrl} type="fill" name="Partner Docs" />
        <QuickLink url={supportUrl} type="outline" name="Support Desk" /> */}
          <QuickLink
            url={onePagerUrl}
            iconRight
            icon={arrowRightSVG}
            type="outline"
            name="One Pager"
          />
          {/* <QuickLink
            onClick={exportToCSV}
            iconLeft
            icon={downloadSVG}
            type="outline"
            name="Download Locations CSV"
          /> */}
        </div>
        <div className={classes.IntegrationInfo}>
          <Supportability />
          <SyncRestriction />
          <SupportedVersions />
          {/* <Distribution /> */}
        </div>
      </div>
      )
    : (
      <Welcome />
      )
}

export default IntegrationInfo
