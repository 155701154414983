import React from 'react'
import classes from './style.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../../store/api/index'
import Spinner from '../Spinner/template'
import { motion } from 'framer-motion'

/**
 * Login screen
 * @returns Login
 */
const Login = () => {
  const loading = useSelector((state) => state.auth.loading)
  const error = useSelector((state) => state.auth.error)
  const dispatch = useDispatch()

  const oktaSignIn = () => {
    dispatch(login())
  }

  return (
    <div className={classes.Container}>
      <div className={classes.LoginContainer}>
        <div className={classes.Bg}>
        </div>
        <motion.div initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }} className={classes.LoginFormContainer}>
          <p className={classes.WelcomeSubHeading}>
            Welcome to,
          </p>
          <p className={classes.WelcomeHeading}>
            Integrations Dashboard
          </p>
          <p className={classes.WelcomeDescription}>Please click on Login button to do Okta login.</p>
          {error && <p className={classes.Error}>Unable to login. Error: {error}</p>}
          <button
            className={classes.LoginButton}
            type="submit"
            name="Login"
            value="Login"
            onClick={oktaSignIn}
          >
            {loading ? <Spinner /> : 'Login'}
          </button>
        </motion.div>
      </div>
    </div>
  )
}

export default Login
